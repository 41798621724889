import  React, { Fragment, useEffect,useRef } from 'react';
import axios from "axios";
import { useState } from "react";

import { useNavigate, Link, Navigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import moment from 'moment';
import './icofont.min.css';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';



function Accueil(){

  let navigate =useNavigate();
  
  moment.locale('fr');
const [isModalOpen, setIsModalOpen] = useState(false);

const [capturedText, setCapturedText] = useState(''); // État local pour stocker le texte transcrit
const [searchResults, setSearchResults] = useState([]); // État local pour stocker les résultats de recherche

const [showFullText, setShowFullText] = useState(false);

const [email, setAuth] = useState('');
const [id, setAuth1] = useState('');


const openModal = () => {
  setIsModalOpen(true);
};

const closeModal = () => {
  setIsModalOpen(false);
};

const [isLoading0, setIsLoading0] = useState(true);
  
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);
    };
    const [filter, setFilter] = useState("");
    const closeMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);	// Code pour fermer le menu
    };

    const [menuOpen0, setMenuOpen0] = useState(false);
    const [menuActive0, setMenuActive0] = useState(false);
    const toggleMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);
    };
    const [progress, setProgress] = useState(0);
    const closeMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);	// Code pour fermer le menu
    };

    const [menuOpen01, setMenuOpen01] = useState(false);
    const [menuActive01, setMenuActive01] = useState(false);
    const toggleMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);
    };
    
    const closeMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);	// Code pour fermer le menu
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true, // Active le mode automatique
      autoplaySpeed: 3000, // Définit la vitesse de défilement automatique (en millisecondes)
    };

    const [isProgressComplete, setProgressComplete] = useState(false);

    const [menuOpen011, setMenuOpen011] = useState(false);
    const [menuActive011, setMenuActive011] = useState(false);
    const toggleMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);
    };
    
    const closeMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);	// Code pour fermer le menu
    };

    const [showProgress, setShowProgress] = useState(false);

    const [menuOpen0111, setMenuOpen0111] = useState(false);
    const [menuActive0111, setMenuActive0111] = useState(false);
    const toggleMenu0111 = () => {
      setMenuOpen0111(!menuOpen0111);
      setMenuActive0111(!menuOpen0111);
    };
    const [isLoading, setIsLoading] = useState(true);
    const closeMenu0111 = () => {
      setMenuOpen0111(!menuOpen0111);
      setMenuActive0111(!menuOpen0111);	// Code pour fermer le menu
    };
    const [agencess, setAgencess] = useState([]);
    const [agences, setAgences] = useState([]);

    const audioRef = useRef(null);
    const [agences6, setAgences6] = useState([]);

    const [agences7, setAgences7] = useState([]);
    const [agences5, setAgences5] = useState([]);
    const [agences9, setAgences9] = useState([]);
    const [agences8, setAgences8] = useState([]);

    const handleShareOnWhatsApp = () => {
      const url = encodeURIComponent(window.location.href);
const text = encodeURIComponent('Venez voir cette publication intéressante !');
const whatsappUrl = `https://api.whatsapp.com/send?text=${text}%20${url}`;
window.open(whatsappUrl, '_blank');
    };

    useEffect(() =>{
        
        const fetchData = async () => {
            try {
              await getAgences();
           
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };
        
          fetchData(); // Exécute fetchData une fois immédiatement
        
          const intervalId = setInterval(fetchData, 1000); // Exécute fetchData toutes les 3 secondes
        
          return () => {
            clearInterval(intervalId); // Nettoie l'intervalle lors du démontage du composant
          };
        }, []);




  

        useEffect(() =>{
        
          const fetchDatas = async () => {
              try {
                await getAgencess();
             
              } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
              }
            };
          
            fetchDatas(); // Exécute fetchData une fois immédiatement
          
            const intervalIds = setInterval(fetchDatas, 1000); // Exécute fetchData toutes les 3 secondes
          
            return () => {
              clearInterval(intervalIds); // Nettoie l'intervalle lors du démontage du composant
            };
          }, []);

        useEffect(() =>{
        
            const fetchData8 = async () => {
                try {
                  await getAgences8();
               
                } catch (error) {
                  console.error('Erreur lors de la récupération des données:', error);
                }
              };
            
              fetchData8(); // Exécute fetchData une fois immédiatement
            
              const intervalId8 = setInterval(fetchData8, 1000); // Exécute fetchData toutes les 3 secondes
            
              return () => {
                clearInterval(intervalId8); // Nettoie l'intervalle lors du démontage du composant
              };
            }, []);


          useEffect(() =>{
        
        const fetchData5 = async () => {
            try {
              await getAgences5();
           
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };
        
          fetchData5(); // Exécute fetchData une fois immédiatement
        
          const intervalId5 = setInterval(fetchData5, 1000); // Exécute fetchData toutes les 3 secondes
        
          return () => {
            clearInterval(intervalId5); // Nettoie l'intervalle lors du démontage du composant
          };
        }, []);


        useEffect(() =>{
        
            const fetchData6 = async () => {
                try {
                  await getAgences6();
               
                } catch (error) {
                  console.error('Erreur lors de la récupération des données:', error);
                }
              };
            
              fetchData6(); // Exécute fetchData une fois immédiatement
            
              const intervalId6 = setInterval(fetchData6, 1000); // Exécute fetchData toutes les 3 secondes
            
              return () => {
                clearInterval(intervalId6); // Nettoie l'intervalle lors du démontage du composant
              };
            }, []);


         



                useEffect(() =>{
        
                  const fetchData9 = async () => {
                      try {
                        await getAgences9();
                     
                      } catch (error) {
                        console.error('Erreur lors de la récupération des données:', error);
                      }
                    };
                  
                    fetchData9(); // Exécute fetchData une fois immédiatement
                  
                    const intervalId9 = setInterval(fetchData9, 1000); // Exécute fetchData toutes les 3 secondes
                  
                    return () => {
                      clearInterval(intervalId9); // Nettoie l'intervalle lors du démontage du composant
                    };
                  }, []);
  


        useEffect(() => {


            
            const loadScripts = () => {
                // Remove existing script elements added by the component
                const scriptElements = Array.from(document.getElementsByTagName('script'));
                const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
          
                componentScriptElements.forEach((script) => {
                  document.body.removeChild(script);
                });
          
          
            
         
              const script1 = document.createElement('script');
              script1.src = '/importe/dependencies/jquery/js/jquery.min.js';
              script1.async = true;
          
              const script2 = document.createElement('script');
              script2.src = '/importe/dependencies/popper.js/js/popper.min.js';
              script2.async = true;
      
              const script3 = document.createElement('script');
              script3.src = '/importe/dependencies/bootstrap/js/bootstrap.min.js';
              script3.async = true;
          
             
              const script5 = document.createElement('script');
              script5.src = '/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
              script5.async = true;
      
      
              const script6 = document.createElement('script');
              script6.src = '/importe/dependencies/isotope-layout/js/isotope.pkgd.min.js';
              script6.async = true;
      
              const script8 = document.createElement('script');
              script8.src = '/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
              script8.async = true;
      
              const script7 = document.createElement('script');
              script7.src = '/importe/dependencies/slick-carousel/js/slick.min.js';
              script7.async = true;
              const script4 = document.createElement('script');
              script4.src = '/importe/dependencies/magnific-popup/js/jquery.magnific-popup.min.js';
              script4.async = true;
      
              const script9 = document.createElement('script');
              script9.src = '/importe/dependencies/sal.js/sal.js';
              script9.async = true;
      
              const script10 = document.createElement('script');
              script10.src = '/importe/dependencies/sal.js/sal.js';
              script10.async = true;
      
              const script11 = document.createElement('script');
              script11.src = '/importe/dependencies/mcustomscrollbar/jquery.mCustomScrollbar.concat.min.js';
              script11.async = true;
      
              const script12 = document.createElement('script');
              script12.src = '/importe/assets/js/app.js';
              script12.async = true;
              const script13 = document.createElement('script');
              script13.src = '/importe/dependencies/select2/js/select2.min.js';
              script13.async = true;
      
      
              const script14 = document.createElement('script');
              script14.src = '/importe/dependencies/elevate-zoom/jquery.elevatezoom.js';
              script14.async = true;
              const script15 = document.createElement('script');
              script15.src = '/importe/dependencies/bootstrap-validator/js/validator.min.js';
              script15.async = true;
      
      
              document.body.appendChild(script1);
              document.body.appendChild(script2);
              document.body.appendChild(script3);
              document.body.appendChild(script8);
              document.body.appendChild(script5);
             
              document.body.appendChild(script6);
              document.body.appendChild(script7);
              document.body.appendChild(script9);
      
      
              document.body.appendChild(script4);
              document.body.appendChild(script10);
              document.body.appendChild(script11);
      
      
              document.body.appendChild(script13);
      
              document.body.appendChild(script14);
      
      
              document.body.appendChild(script15);
      
              document.body.appendChild(script12);
             
             
            };
              loadScripts();
          
          
              return () => {
                const scriptElements = Array.from(document.getElementsByTagName('script'));
                const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
          
                componentScriptElements.forEach((script) => {
                  document.body.removeChild(script);
                });
              };
        

        }, []);


    useEffect(() => {
        
          
            setIsLoading(true);



     
            const htmlElement = document.getElementById('wrapper');
            const htmlElement0 = document.getElementById('menu0');
            const htmlElement01 = document.getElementById('chat-box-modal');
            const htmlElement011 = document.getElementById('searchBtn');
            const htmlElement0111 = document.getElementById('users0');
            const htmlElement01111 = document.getElementById('users1');


            
            if (htmlElement) {
                if (menuActive) {
                  htmlElement.classList.add('open');
    
                  document.getElementById('menu1').classList.add('lg-menu-open');
                
                } else {
                  htmlElement.classList.remove('open');
                  document.getElementById('menu1').classList.remove('lg-menu-open');
                 
                }
              }
    
              if (htmlElement0) {
                if (menuActive0) {
                  htmlElement0.classList.add('chat-head-hide');
    
                
                
                } else {
                  htmlElement0.classList.remove('chat-head-hide');
               
                 
                }
              }
    
              if (htmlElement01) {
                if (menuActive01) {
                  htmlElement01.classList.add('modal-show');
    
                
                
                } else {
                  htmlElement01.classList.remove('modal-show');
               
                 
                }
    
               
              }


    
              if (htmlElement011) {
              if (menuActive011) {
                htmlElement011.classList.add('show');
    
              
              
              } else {
                htmlElement011.classList.remove('show');
             
               
              }
            }

            
            if (htmlElement0111) {
                if (menuActive0111) {
                  htmlElement0111.classList.add('show');
                  htmlElement01111.classList.add('show');
      
                
                
                } else {
                  htmlElement0111.classList.remove('show');
                  htmlElement01111.classList.add('show');
      
                 
                }
              }
      
            
        
        }, [menuActive,menuActive0,menuActive01,menuActive011]);
        
   
       

         const getAgencess = async () => {
          try {
            const response = await axios.get(`/dist81/`);
            setAgencess(response.data);
            setIsLoading(false);
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        };
          const getAgences = async () => {
            try {
              const response = await axios.get(`/dist85/`);
              setAgences(response.data);
              setIsLoading(false);
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };
          const getAgences5 = async () => {
            try {
              const response = await axios.get(`/dist855/${localStorage.getItem('id')}`);
              setAgences5(response.data);
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };

           const getAgences6 = async () => {
            try {
              const response = await axios.get(`/dist856/${localStorage.getItem('id')}`);
              setAgences6(response.data);
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };

          const getAgences9 = async () => {
            try {
              const response = await axios.get(`/dist859/${localStorage.getItem('id')}`);
              setAgences9(response.data);
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };


        
          const getAgences8 = async () => {
            try {
              const response = await axios.get(`/dist858/${localStorage.getItem('id')}`);
              setAgences8(response.data);
        
            } catch (error) {
              console.error('Erreur lors de la récupération des données:', error);
            }
          };
    
    
        const handleFilterChange = (event) => {
            setFilter(event.target.value);
          };

      
          function handleFileChange(event) {
            const files = event.target.files; // Récupérer tous les fichiers sélectionnés
            const previewElement = document.getElementById("file-preview");
          
            // Parcourir chaque fichier sélectionné
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              const fileURL = URL.createObjectURL(file);
          
              // Créer un élément de div pour chaque colonne
              const columnElement = document.createElement("div");
              columnElement.classList.add("col-3");
          
              // Vérifier si le fichier est une image
              if (file.type.includes("image")) {
                // Créer un élément d'image pour afficher la prévisualisation
                const imageElement = document.createElement("img");
                imageElement.src = fileURL;
                imageElement.alt = "Fichier image";
          
                // Ajouter l'élément d'image à la colonne
                columnElement.appendChild(imageElement);
              }
              // Vérifier si le fichier est une vidéo
              else if (file.type.includes("video")) {
                // Créer un élément vidéo pour afficher la prévisualisation
                const videoElement = document.createElement("video");
                videoElement.src = fileURL;
                videoElement.type = file.type;
                videoElement.width = 80;
                videoElement.controls = true;
          
                // Ajouter l'élément vidéo à la colonne
                columnElement.appendChild(videoElement);
              }else if (file.type.includes("audio")) {
                // Créer un élément vidéo pour afficher la prévisualisation
                const audioElement = document.createElement("audio");
                audioElement.src = fileURL;
                audioElement.type = file.type;
                audioElement.width = 80;
                audioElement.controls = true;
          
                // Ajouter l'élément vidéo à la colonne
                columnElement.appendChild(audioElement);
              }
          
              // Ajouter la colonne au preview
              previewElement.appendChild(columnElement);
            }
          }
          
          
          

const handleImageUpload = () => {
    const fileInput = document.getElementById('file-input');
    fileInput.click();
  };

  const handlePublish = () => {
    setShowProgress(true); // Afficher la barre de progression
  
    // Réinitialiser les champs si la progression est complétée
    if (isProgressComplete) {
      document.getElementById('status-input2').value = '';
      document.getElementById('file-input').value = '';
      document.getElementById('file-preview').innerHTML = '';

      
     
      setShowProgress(false);
      setProgress(0);
      setProgressComplete(false);
      return;
    }
  
    // Récupérez les données du formulaire (image et texte)
    const statusInput = document.getElementById('status-input2').value;
    const fileInput = document.getElementById('file-input').files;
  
    // Créez un objet FormData pour envoyer les données
    const formData = new FormData();
    formData.append('statusInput', statusInput);
    for (let i = 0; i < fileInput.length; i++) {
      formData.append('files[]', fileInput[i]);
    }
    const id = localStorage.getItem('id');
    setAuth(email);
    setAuth1(id);
    // Effectuez la requête POST en utilisant Axios
    axios.post(`/dist84/${localStorage.getItem('id')}`, formData, {
      onUploadProgress: (progressEvent) => {
        const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // Mettez à jour l'état de la progression ici
        setProgress(progressPercentage);
  
        if (progressPercentage === 100) {
          setProgressComplete(true); // Indiquer que la progression est complétée
          document.getElementById('status-input2').value = '';
          document.getElementById('file-input').value = '';
          document.getElementById('file-preview').innerHTML = '';
 document.getElementById('rei').click();
          audioRef.current.play();
          console.log(formData);
        }
      },
    })
      .then(response => {
        // Gérez la réponse de la requête si nécessaire
        console.log(response.data);
      })
      .catch(error => {
        // Gérez les erreurs de la requête
        console.error(error);
      });
  };

  const agencesByIdp = agences.reduce((acc, agence) => {
    const { idp } = agence;
    if (!acc[idp]) {
      acc[idp] = [];
    }
    acc[idp].push(agence);
    return acc;
  }, {});
  
  const uniqueIdps = Object.keys(agencesByIdp);

  const handleClick = (imageUrl, additionalValue) => {
    
    const formData1 = new FormData();
    formData1.append('imageUrl', imageUrl);
    formData1.append('additionalValue', additionalValue);
    // Envoyer l'URL de l'image et la valeur supplémentaire en tant que POST
    axios.post("/dist86/", formData1)
      .then(response => {
        console.log("imageUrl:", imageUrl);
        console.log("additionalValue:", additionalValue);
        console.log(response.data);
        document.getElementById('oii').click();
        audioRef.current.play();
      })
      .catch(error => {
        console.error(error);
      });
  };
  
  const handleClick1 = (imageUrl1, additionalValue1) => {
    
    const formData11 = new FormData();
    formData11.append('imageUrl', imageUrl1);
    formData11.append('additionalValue', additionalValue1);
    // Envoyer l'URL de l'image et la valeur supplémentaire en tant que POST
    axios.post("/dist87/", formData11)
      .then(response => {
       
        document.getElementById('oii').click();
        audioRef.current.play();
      })
      .catch(error => {
        console.error(error);
      });
  };
    


  const handleClick2 = (dest, profil, nom, prenom) => {
    
    setIsLoading0(true);
    document.getElementById('chat-modal-label').innerHTML=prenom +' '+ nom;
    document.getElementById('idr').value=dest;
    window.localStorage.setItem('ids', dest);
    
    document.getElementById('prof').src='/importe/media/' + profil;
    // const formData11 = new FormData();
    // formData11.append('imageUrl', imageUrl1);
    // formData11.append('additionalValue', additionalValue1);
    // // Envoyer l'URL de l'image et la valeur supplémentaire en tant que POST
    // axios.post("/dist87/", formData11)
    //   .then(response => {
       
    //     document.getElementById('oii').click();
    //     audioRef.current.play();
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  };


  const [user, setUser]=useState({
    message: '',
    idr:''

  })
  const handleChangeu = (e) => {

    setUser(({...user, [e.target.name]: e.target.value}));

}



const submitFormu=(e) => {
e.preventDefault();
const sendData = {
    message:user.message,
    idr:localStorage.getItem('ids')

}

    setIsLoading(true);
console.log(sendData);
axios.post(`/dist877/${localStorage.getItem('id')}`,sendData).then((result)=>{
    audioRef.current.play();
document.getElementById('messs').value='';


}

)

}

const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Définissez la largeur de l'écran à laquelle vous souhaitez réagir
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Vérifiez l'état initial de la largeur de l'écran

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logOut = () => {
    localStorage.removeItem('id');
   
    localStorage.clear();
    navigate('/');
  };
  
const submitForm0=(e) => {
  e.preventDefault();
  const sendData0 = {
    commment:document.getElementById('commment').value,
    idp:document.getElementById('idpu').value
 
  }
  
      setIsLoading(true);
  console.log(sendData0);
  axios.post(`/dist8770/${localStorage.getItem('id')}`,sendData0).then((result)=>{
  
navigate(`/Comment/${document.getElementById('idpu').value}`);
  
  }
  
  )
  
  }


  const { transcript, resetTranscript, listening } = useSpeechRecognition({ continuous: true });

  useEffect(() => {
    if (transcript) {
      setCapturedText(transcript); // Mettre à jour l'état local avec le texte transcrit
    }
  }, [transcript]);

  useEffect(() => {
    if (capturedText) {
      // Envoyer le texte transcrit au serveur PHP en utilisant une requête POST
   
      const sendData = {
        query:capturedText
    
    }
    axios.post('/search/',sendData).then((result)=>{
      
    
      setSearchResults(result.data); // Mettre à jour l'état local avec les résultats de recherche

  });
      resetTranscript(); // Réinitialise le texte transcrit après l'envoi
    }
  }, [capturedText]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition) {
    return <div>La reconnaissance vocale n'est pas prise en charge par votre navigateur.</div>;
  }

 
    return(


    <Fragment>

<a href="#wrapper" data-type="section-switch" className="scrollup">
        <i className="icofont-bubble-up"></i>
    </a>
   
   
    <div id="wrapper" className="wrapper">
      
    <header className="fixed-header">
            <div className="header-menu">
            <div className="navbar">
                    <div className="nav-item d-none d-sm-block">
                        <div className="header-logo bg-info">
                            <Link to='/'><img src="/importe/media/logothezik.png" style={{height:'60px'}} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="nav-item nav-top-menu">
                        <nav id="dropdown" className="template-main-menu">
                            <ul className="menu-content">
                                <li className="header-nav-item">
                                    <Link to='/' className="menu-link active">Accueil</Link>
                                </li>
                              
                                <li className="header-nav-item">
                                            <Link to='/Videos'>Clips vidéos</Link>
                                 </li>

                                 <li className="header-nav-item">
                                            <Link to='/Musics'>Clips mp3</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="nav-item header-control">

                   


                        <div className="inline-item">
                            <div className="dropdown dropdown-admin">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <span className="media">
                                    {!localStorage.getItem('id') ? (    
                        <>
                                        <span style={{borderRadius:'100%'}} className="item-img bg-white">
                                       



                                            <img src={`/importe/media/logothezik.png`} style={{height:'44PX',width:'44px'}} alt="Chat" />
                                            <span className="acc-verified"><i className="icofont-check"></i></span>
                                        </span>    
                                        <span className="media-body">
                                            <span className="item-title">THEZIK</span>
                                        </span>
                                            </>              
                                ):(
                                  <>
                                 <span style={{borderRadius:'100%'}} className="item-img bg-white">
                                       
                                   <img src={`/importe/media/${localStorage.getItem('profil')}`} style={{height:'44PX',width:'44px'}} alt="Chat" />
                                           
                                           <span className="acc-verified"><i className="icofont-check"></i></span>
                                        </span> 
                                        <span className="media-body">
                                            <span className="item-title">{localStorage.getItem('prenom')}</span>
                                        </span>   
                                            </>
                                )  }         
                                      
                                           
                                        
                                    </span>
                                </button>
                                {localStorage.getItem('id') ? (    
                        <>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="admin-options">
                                    <li><Link to='/Compte'>Mon compte</Link></li>
                                  
                                     
                                    </ul>
                                </div>
</>):(
    <></>
)}

                            </div>
                        </div>
                     
                   


                    </div>
                </div>
                
            </div>
        </header>
     
        <div className="fixed-sidebar" id="menu1">
            <div className="fixed-sidebar-left small-sidebar">
                <div className="sidebar-toggle">
                    <button onClick={toggleMenu} className="toggle-btn toggler-open">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className="sidebar-menu-wrap">
                    <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                        <ul className="side-menu">
                            <li><Link to='/' className="menu-link text-info" data-toggle="tooltip" data-placement="right" title=" ACCUEIL"><i className="icofont-home"></i></Link></li>
                            <li><Link to='/Videos' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS-VIDEOS"><i className="icofont-play-alt-1"></i></Link></li>
                            <li><Link to='/Musics' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS MP3"><i className="icofont-music"></i></Link></li>
                            {localStorage.getItem('id') ? (    
                       <li><Link to='/Compte' className="menu-link" data-toggle="tooltip" data-placement="right" title="MON COMPTE"><i className="icofont-user"></i></Link></li>
                       ):(
                          <></>
                        )}
                            <li><Link to='/Apropos' className="menu-link" data-toggle="tooltip" data-placement="right" title="A PROPOS"><i className="icofont-question"></i></Link></li>
                            {localStorage.getItem('id') ? (    
                        
                        <li><Link to='/' onClick={logOut} className="menu-link" data-toggle="tooltip" data-placement="right" title="DECONNEXION"><i className="icofont-power"></i></Link></li>
                          ):(
                            <li><Link to='/Login' className="menu-link" data-toggle="tooltip" data-placement="right" title="CONNEXION"><i className="icofont-power"></i></Link></li>
                        
                        )}
                           
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fixed-sidebar-left large-sidebar bg-info">
                <div className="sidebar-toggle">
                    <div className="sidebar-logo bg-info">
                        <a href=""><img src="/importe/media/logothezik.png" style={{height:'50px'}} alt="Logo" /></a>
                    </div>
                    <button onClick={closeMenu} className="toggle-btn toggler-close">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className="sidebar-menu-wrap">
                    <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                        <ul className="side-menu">
                            <li><Link to='/Accueil' className="menu-link text-info"><i className="icofont-home"></i><span className="menu-title">Accueil</span></Link></li>
                            <li><Link to='/Videos' className="menu-link active"><i className="icofont-play-alt-1"></i><span className="menu-title active">Clips-vidéos</span></Link></li>
                            <li><Link to='/Musics' className="menu-link"><i className="icofont-music"></i><span className="menu-title">Clips mp3</span></Link></li>
                            {localStorage.getItem('id') ? (    
                        
                        <li><Link to='/Compte' className="menu-link"><i className="icofont-user"></i><span className="menu-title">Mon compte</span></Link></li>
                        ):(
                          <></>
                        )}
                             <li><Link to='/Apropos' className="menu-link"><i className="icofont-question"></i><span className="menu-title">A propos</span></Link></li>
                            {localStorage.getItem('id') ? (    
                        
                            <li><Link to='/' onClick={logOut} className="menu-link"><i className="icofont-power"></i><span className="menu-title">Deconnexion</span></Link></li>
                            ):(
                              <li><Link to='/Login' className="menu-link"><i className="icofont-power"></i><span className="menu-title">Connexion</span></Link></li>
                          
                            )}
                           </ul>
                        
                    </div>
                </div>
            </div>
        </div>
      
        <div className="fixed-sidebar right" id='menu0'>
            <div className="fixed-sidebar-right small-sidebar">
                <div className="sidebar-toggle" id="chat-head-toggle">
                    <button className="chat-icon">
                    {!listening ? (
                        <i onClick={SpeechRecognition.startListening} className='icofont-audio'></i>
              ) : (
                <img style={{height:'30PX',borderRadius:'100%'}} src='/assets/media/au.gif'/>
                   
      )} </button>
                </div>
                {/* <div className="sidebar-menu-wrap">
                    <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                  
                    </div>
                </div> */}
            </div>
        </div>
 
 <div className="page-content">

<div className="container">


                <div style={{display:'none'}} className="newsfeed-search">
                {isMobile ? 
   
                <ul className="nav nav-tabs" role="tablist">
                     <li className="nav-item">
                         <a className="nav-link active" data-toggle="tab" href="#newest-member" role="tab" aria-selected="true">  <i className="icofont-pencil"></i> <div style={{borderRadius:'100%'}} className="badge badge-danger">{agences.length}</div>
                              </a>
                     </li>
                     {localStorage.getItem('id') && (    
                        <>
                     <li className="nav-item">
                         <a className="nav-link" data-toggle="tab" href="#popular-member" role="tab" aria-selected="false">  <i className="icofont-speech-comments"></i> <div style={{borderRadius:'100%'}} className="badge badge-danger">{agences5.length}</div></a>
                     </li>
                     <li className="nav-item">
                         <a className="nav-link" data-toggle="tab" href="#active-member" role="tab" aria-selected="false">  <i className="icofont-users"></i> <div style={{borderRadius:'100%'}} className="badge badge-danger">{agences8.length}</div></a>
                     </li>
                     </>)}
                 </ul>
                    : 
                    
                    
                <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#newest-member" role="tab" aria-selected="true" style={{fontSize:'20PX'}}><b>Publications</b><div style={{borderRadius:'100%'}} className="badge badge-danger">{agences.length}</div>
                         </a>
                </li>
                {localStorage.getItem('id') && (    
                        <>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#popular-member" role="tab" aria-selected="false" style={{fontSize:'20PX'}}><b>Discussions</b><div style={{borderRadius:'100%'}} className="badge badge-danger">{agences5.length}</div></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#active-member" role="tab" aria-selected="false" style={{fontSize:'20PX'}}><b>Utilisateurss</b><div style={{borderRadius:'100%'}} className="badge badge-danger">{agences8.length}</div></a>
                </li></>)}
            </ul>
            
            }
                    <ul className="search-list">
                     
                        <li className="search-input">
                            <button className="drop-btn" onClick={toggleMenu011} id='searchBtn' type="button">
                                <i className="icofont-search"></i>
                            </button>
                          
                        </li>
                    </ul>
                </div>

                {searchResults.length > 0 && (
          <>
          <div className="block-box load-more-btn col-lg-12">
            
          <a href="https://thezik.org/Accueil" className="item-btn"><i className="icofont-trash"></i>Effacer le résultat</a>
      </div>
     
      {!isMobile ? 
                    <>
      <div id="user-view" className="user-grid-view">

        <div className="row gutters-20">





      
          
 {searchResults.map(resultt => (
                          <>
                           {resultt.mp4!='' && (
                            <>
    
<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img" style={{height:'200PX'}}>
                                        </div>
                                    <div className="profile-img user-video bg-warning">
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-play"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h4 className=" "> <Link style={{color:'black'}} className="" to={`/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-primary" style={{fontSize:'17PX'}}>{resultt.taille2}</span><br />

                                 </Link>
                                </h4>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                           )}

{resultt.mp3!='' && (
                            <>
     

<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                       </div>
                                    <div className="profile-img user-video bg-warning" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/Musics/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-music"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h4 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/Musics/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-primary" style={{fontSize:'17PX'}}>{resultt.taille}</span><br />

                                 </Link>
                                </h4>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                           )}
                            </>
                           ))}
          </div></div>
          
          </>:
           <>
           <div id="user-view" className="user-list-view">

<div className="row gutters-20">






  
{searchResults.map(resultt => (
                  <>
                   {resultt.mp4!='' && (
                    <>


<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                        <img style={{height:'96PX',width:'277PX'}} src={`/${resultt.image}`} alt="cover" />
                                    </div>
                                    <div className="profile-img user-video" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-play"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h5 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-warning" style={{fontSize:'15PX'}}>{resultt.taille2}</span><br />

                                 </Link>
                                </h5>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                   )}

{resultt.mp3!='' && (
                    <>


<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                             </div>
                                    <div className="profile-img user-video" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/Musics/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-music"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h5 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/Musics/${resultt.id}`}>{resultt.artist.length > 12
                    ? `${resultt.artist.slice(0, 12)}...`
                    : resultt.artist}
                               <br />
                               <span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-warning" style={{fontSize:'15PX'}}>{resultt.taille}</span><br />

                                 </Link>
                                </h5>
                                   </div>
                                </div>
                              
                            </div>
                        </div>


</>
                   )}
                    </>
                   ))}
  </div></div>
           </>}  
           
           
            </>
        )}


                <div className="row">
                   

                   
                  

{/* 
                    {localStorage.getItem('id') && localStorage.getItem('id')==2 && (    
                        <>      
                        <div className="block-box post-input-tab">
                            <ul className="nav nav-tabs" role="tablist">
                          
      {!isMobile ? 
                                <li className="nav-item" role="presentation" data-toggle="tooltip" data-placement="top" title="Photo/ Video publication">
                                    <a style={{fontSize:'20PX'}} className="nav-link active" data-toggle="tab" href="#post-media" role="tab" aria-selected="false"><i className="icofont-pencil"></i>Publier</a>
                                </li>
                                : <li className="nav-item" role="presentation" data-toggle="tooltip" data-placement="top" title="Photo/ Video publication">
                                <a className="nav-link active" data-toggle="tab" href="#post-media" role="tab" aria-selected="false"><i className="icofont-pencil"></i>Publier</a>
                            </li>}
                               
                            </ul>
                            <div className="tab-content">
                               
                                <div className="tab-pane fade show active" id="post-media" role="tabpanel">

                                <div className="file-upload">
        <input type="file" style={{display:'none'}} id="file-input"  onChange={(event) => handleFileChange(event)}  accept="image/*, audio/*, video/*" multiple />
        
      </div>
 

      <div id="file-preview" className="row"></div>


      {!isMobile ? 
      <textarea style={{fontSize:'20PX'}} name="status-input" id="status-input2" className="form-control textarea" required placeholder="Partagez une pensée en rapport avec la musique . . ." cols="30" rows="6"></textarea>
    : <textarea name="status-input" id="status-input2" className="form-control textarea" required placeholder="Partagez une pensée en rapport avec la musique . . ." cols="30" rows="6"></textarea>
  } 
     
     <div align='center' id='barre' style={{textAlign:'center'}}>
      {showProgress && progress < 100 && <progress value={progress} max="100" />}
      <audio style={{display:'none'}} ref={audioRef} src="/assets/media/success.mp3" />

       </div>              
        	               </div>
                                
                            </div>





                 <div className="post-footer">
                 <div className="insert-btn">
                 {!isMobile ? 
  <span className="badge badge-info text-white" style={{borderRadius:'100%',fontSize:'20PX'}} onClick={handleImageUpload}><i className="icofont-camera text-white"></i></span>
  :
  <span className="badge badge-info text-white" style={{borderRadius:'100%'}} onClick={handleImageUpload}>
    
    <a><i className="icofont-camera text-white"></i></a>
    </span>
 
                 }
</div>
<div className="submit-btn">
    
  <a id="rei" onClick={handlePublish}>   {isProgressComplete ? 'Réinitialiser' : 'Publier maintenant'}</a>
</div>
                 </div>
             </div>
             
</>
                    )} */}

             {Object.keys(agencesByIdp).map((idp) => (
      <div key={idp} className=' col-lg-4'>
     
     
             <div className="block-box post-view"> 
                 <div className="post-header">
                     <div className="media">
                         <div className="user-img">
                         {[...new Set(agencesByIdp[idp].map((agence) => agence.profil))].map((profil, key) => (
 <img key={key} src={`/importe/media/${profil}`} style={{height:'44PX',width:'44px'}} alt="Aahat" />

))}
                               </div>
                         <div className="media-body">
                             <div className="user-title"><a href="#">
                             {!isMobile ? 
   <>
                             {[...new Set(agencesByIdp[idp].map((agence) => agence.prenom))].map((prenom, key) => (
  <span key={key}>{prenom}</span>
))} {[...new Set(agencesByIdp[idp].map((agence) => agence.nom))].map((nom, key) => (
  <span key={key}>{nom}</span>
))}
</>
:<>
{[...new Set(agencesByIdp[idp].map((agence) => agence.prenom))].map((prenom, key) => (
  <span key={key}>{prenom}</span>
))} {[...new Set(agencesByIdp[idp].map((agence) => agence.nom))].map((nom, key) => (
  <span key={key}>{nom}</span>
))}</>
}
</a>{[...new Set(agencesByIdp[idp].map((agence) => agence.prenom))].map((prenom, key) => (
  <>
 
  <i className="icofont-check"></i>

 </>
))} </div>
{!isMobile ? 
  <>
 
     {[...new Set(agencesByIdp[idp].map((agence) => agence.date_at))].map((date_at, keyo) => (
        
        <div key={keyo}>
          <ul className="entry-meta text-success">
            <li className="meta-privacy text-success"><i className="icofont-world"></i> Public</li>
            <li className="meta-time text-success">{moment(date_at).fromNow()}</li>
          </ul>
          {/* Autres éléments de votre code JSX pour chaque agence */}
        </div>
      ))}
   

                </> : <>
 
             
     {[...new Set(agencesByIdp[idp].map((agence) => agence.date_at))].map((date_at, keyo) => (
        <div key={keyo}>
          <ul className="entry-meta text-success">
            <li className="meta-privacy text-success"><i className="icofont-world"></i> Public</li>
            <li className="meta-time text-success">{moment(date_at).fromNow()}</li>
          </ul>
          {/* Autres éléments de votre code JSX pour chaque agence */}
        </div>
      ))}
   
                </>   
}               
                         </div>
                     </div>
                    
                 </div>
                 <div className="post-body">
                 {[...new Set(agencesByIdp[idp].map((agence) => agence.texte))].map((texte, key) => {
    const maxTextLength = isMobile ? 150 : 150;
    const isLongText = texte.length > maxTextLength;
    const displayedText = isLongText ? (showFullText ? texte : `${texte.substring(0, maxTextLength)}...`) : texte;

    return (
      <p key={key}>
        <font style={{ textJustify: 'justify',color:'black' }}>
          {displayedText}
          {isLongText && (
            <a className='text-info text-bold' onClick={() => setShowFullText(!showFullText)}>
              {showFullText ? 'Voir moins' : 'Voir plus'}
            </a>
          )}
        </font>
      </p>
    );
  })}






{agencesByIdp[idp].length > 1 ? (
  <ul className="post-img-list">
    {agencesByIdp[idp].map((agence, key) => (
        <>
        {agence.type_file === 'image/jpeg' || agence.type_file === 'image/png' || agence.type_file === 'image/jpg' ? (
      <li key={key}>
      <a href={`/thezik/${agence.files}`} data-photo="+">
    <img
            src={`/thezik/${agence.files}`}
            alt="Post"
          />
        </a>
       {/* <Modal isOpen={isModalOpen} style={{height:'200px',width:'300px'}} onRequestClose={closeModal}>
  
 <img
  src={`/importe/media/${agence.files}`}
  alt="Post"
  className="modal-image" style={{ width: '100%',
   
    borderRadius: '8px'}}
/>
 
</Modal> */}

      </li>

        ): agence.type_file === 'video/mp4' ? (
<>
<li key={key}>
      <a href={`/thezik/${agence.files}`} data-photo="+">
      <video controls style={{width:'100%'}}><source src={`/thezik/${agence.files}`} alt="Post" ></source></video>
             
          </a>
</li>
        
        </>
        
        
       ) :agence.type_file === 'audio/mp3' ? (
        <>
        <li key={key}>
              <a href={`/thezik/${agence.files}`} data-photo="+">
              <audio
              controls
              style={{ width: '100%' }}
              src={`/thezik/${agence.files}`}
              alt="Post"
            ></audio>  
                   
                  </a>
        </li>
                
                </>
                
                
               ) :(
        <></>
       )
    
    
    }
        </>
    ))}
  </ul>

 
) : agencesByIdp[idp].length === 0 ? (
    <div></div>
  ) : (


  <div className="post-img" style={{width:'100%'}}>
    {agencesByIdp[idp].map((agence, key) => (
        <>
         {agence.files !== '' ? ( 
        <>
        {agence.type_file === 'video/mp4' || agence.type_file === 'video/avi' ? (
         


<div className="user-video">
<div align='center'>    <img style={{maxHeight:'350PX',width:'100%'}}
            key={key}
            src={`/thezik/${agence.image}`}
            alt="Post"
          />
</div>

<div className="video-btn">

<Link to={`/Comment/${idp}`} className="play-icon">
    <i className="icofont-ui-play"></i>
</Link>
</div>
</div>




          ) : agence.type_file === 'image/jpeg' || agence.type_file === 'image/png' || agence.type_file === 'image/jpg' ? (
            <div className="user-video">
            <div align='center'>    <img style={{maxHeight:'350PX',width:'100%'}}
                        key={key}
                        src={`/thezik/${agence.image}`}
                        alt="Post"
                      />
            </div>
            
            <div className="video-btn">
            
            <Link to={`/Comment/${idp}`} className="play-icon">
                <i className="icofont-ui-play"></i>
            </Link>
            </div>
            </div>
  ) :agence.type_file === 'audio/mp3' || agence.type_file === 'audio/mpeg' ? (
    <>

<div className="user-video">
<div align='center'>    <img style={{maxHeight:'350PX',width:'100%'}}
            key={key}
            src={`/thezik/${agence.image}`}
            alt="Post"
          />
</div>

<div className="video-btn">

<Link to={`/Comment/${idp}`} className="play-icon">
    <i className="icofont-ui-music"></i>
</Link>
</div>
</div>

            
            </>
            
            
           ) : (
           <>
           </>
          ) 
          
          } 
 </>

         ) : (
            <></>
         )
        }
         </>














    )
    
    
    
    
    
    )}
  </div>



)}

{!isMobile ? 
                    <>
                    <div className="post-meta-wrap">
                    <div className="post-meta">
                        <div className="post-reaction">
                            <div className="reaction-icon">
                               
                               
                                {[...new Set(agencesByIdp[idp].map((agence) => agence.react1))].map((react1, key) => (
                         <>
                         {react1 !== '' ? (
                         <img src={react1} alt="icon" />
                         ):(
                           <>
                           </>
                         )}
                         </>
                        ))}
                               {[...new Set(agencesByIdp[idp].map((agence) => agence.react2))].map((react2, key) => (
                         <>
                         {react2 !== '' ? (
                         <img src={react2} alt="icon" />
                         ):(
                           <>
                           </>
                         )}
                         </>
                        ))}
                             </div>
                            <div className="meta-text"> {[...new Set(agencesByIdp[idp].map((agence) => agence.aime))].map((aime, key) => (
                         aime
                        ))} Réact</div>
                        </div>
                    </div>
                    <div className="post-meta">
                        <div className="meta-text"> {[...new Set(agencesByIdp[idp].map((agence) => agence.comment))].map((comment, key) => (
                         comment
                        ))} Comment.</div>
                        <div className="meta-text">{[...new Set(agencesByIdp[idp].map((agence) => agence.share))].map((share, key) => (
                         share
                        ))} Part.</div>
                    </div>
                </div>
  </> :   
   <>
   <div className="post-meta-wrap">
                         <div className="post-meta">
                             <div className="post-reaction">
                                 <div className="reaction-icon">
                                    
                                    
                                     {[...new Set(agencesByIdp[idp].map((agence) => agence.react1))].map((react1, key) => (
                              <>
                              {react1 !== '' ? (
                              <img src={react1} alt="icon" />
                              ):(
                                <>
                                </>
                              )}
                              </>
                             ))}
                                    {[...new Set(agencesByIdp[idp].map((agence) => agence.react2))].map((react2, key) => (
                              <>
                              {react2 !== '' ? (
                              <img src={react2} alt="icon" />
                              ):(
                                <>
                                </>
                              )}
                              </>
                             ))}
                                  </div>
                                 <div className="meta-text"> {[...new Set(agencesByIdp[idp].map((agence) => agence.aime))].map((aime, key) => (
                              aime
                             ))} Réact</div>
                             </div>
                         </div>
                         <div className="post-meta">
                             <div className="meta-text"> {[...new Set(agencesByIdp[idp].map((agence) => agence.comment))].map((comment, key) => (
                              comment
                             ))} Comment.</div>
                             <div className="meta-text">{[...new Set(agencesByIdp[idp].map((agence) => agence.share))].map((share, key) => (
                              share
                             ))} Part.</div>
                         </div>
                     </div>
  </>
} 
                    
                 </div>
                 {!isMobile ? 
                    <>        
                 <div className="post-footer">
                     <ul>
                         <li className="post-react">
                             <a><i className="icofont-thumbs-up"></i>Réag!</a>
                             <ul id='oii' className="react-list">
                                
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_1.png", idp)}><img src="/importe/media/figure/reaction_1.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_2.png", idp)}><img src="/importe/media/figure/reaction_2.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_4.png", idp)}><img src="/importe/media/figure/reaction_4.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_3.png", idp)}><img src="/importe/media/figure/reaction_3.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_7.png", idp)}><img src="/importe/media/figure/reaction_7.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_6.png", idp)}><img src="/importe/media/figure/reaction_6.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_5.png", idp)}><img src="/importe/media/figure/reaction_5.png" alt="Like" /></a></li>
                             </ul>
                         </li>
                         <li><Link to={`/Comment/${idp}`}><i className="icofont-comment"></i>Comm.</Link></li>
                         <li className="post-share">
                             <a href="javascript:void(0);" className="share-btn"><i className="icofont-share"></i></a>
                             <ul className="share-list">
                                <li><a onClick={() => handleClick1("icofont-brand-whatsapp", idp)} className="color-whatsapp"><i onClick={handleShareOnWhatsApp} className="icofont-brand-whatsapp"></i></a></li>
   
                             </ul>
                         </li>
                     </ul>
                 </div>
</>:<>
<div className="post-footer">
                     <ul>
                         <li className="post-react">
                             <a><i className="icofont-thumbs-up"></i>Réag!</a>
                             <ul id='oii' className="react-list">
                                
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_1.png", idp)}><img src="/importe/media/figure/reaction_1.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_2.png", idp)}><img src="/importe/media/figure/reaction_2.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_4.png", idp)}><img src="/importe/media/figure/reaction_4.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_3.png", idp)}><img src="/importe/media/figure/reaction_3.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_7.png", idp)}><img src="/importe/media/figure/reaction_7.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_6.png", idp)}><img src="/importe/media/figure/reaction_6.png" alt="Like" /></a></li>
                                 <li><a  onClick={() => handleClick("/importe/media/figure/reaction_5.png", idp)}><img src="/importe/media/figure/reaction_5.png" alt="Like" /></a></li>
                             </ul>
                         </li>
                         <li><Link to={`/Comment/${idp}`}><i className="icofont-comment"></i>Comm.</Link></li>
                        
                         <li className="post-share">
                             <a href="javascript:void(0);" className="share-btn"><i className="icofont-share"></i></a>
                             <ul className="share-list">

                                 <li><a onClick={() => handleClick1("icofont-brand-whatsapp", idp)} className="color-whatsapp"><i  onClick={handleShareOnWhatsApp} className="icofont-brand-whatsapp"></i></a></li>
                                
                             </ul>
                         </li>
                     </ul>
                 </div>
</>
}
            



             </div>

             </div>


))}













{isLoading ? (
    <div align='center' className="load-more-btn col-lg-12">
   <img style={{height:'100PX'}} src={`/importe/media/ok.gif`} alt="Blog" />
   </div>
        ) : ( 
          <div className="block-box load-more-btn col-lg-12">
          <a href="#" className="item-btn"><i className="icofont-refresh"></i>Charger plus de publications</a>
      </div>
        )}
             
             





      
     </div>
 </div>





</div>























    </div>
</Fragment>
    )

}

export default Accueil;