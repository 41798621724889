import React, { Fragment, useEffect, useState } from 'react';
import axios from "axios";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function Login1() {
  const [capturedText, setCapturedText] = useState(''); // État local pour stocker le texte transcrit

  const { transcript, resetTranscript, listening } = useSpeechRecognition({ continuous: true });

  useEffect(() => {
    if (transcript) {
      setCapturedText(transcript); // Mettre à jour l'état local avec le texte transcrit
    }
  }, [transcript]);

  useEffect(() => {
    if (capturedText) {
      // Envoyer le texte transcrit au serveur PHP en utilisant une requête POST
   
      const sendData = {
        query:capturedText
    
    }
    axios.post('/search/',sendData).then((result)=>{
      
     console.log(result.data);
  });
      resetTranscript(); // Réinitialise le texte transcrit après l'envoi
    }
  }, [capturedText]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition) {
    return <div>La reconnaissance vocale n'est pas prise en charge par votre navigateur.</div>;
  }

  return (
    <Fragment>
      {!listening ? (
        <button onClick={SpeechRecognition.startListening}>Démarrer la reconnaissance vocale</button>
      ) : (
        <p>Écoute en cours...</p>
      )}
      <p>Texte transcrit : {capturedText}</p> {/* Afficher le texte transcrit */}
    </Fragment>
  );
}

export default Login1;
