import  React, { Fragment, useEffect } from 'react';
import axios from "axios";
import { useState } from "react";
import { useNavigate, Link, useParams } from 'react-router-dom';



import './icofont.min.css';


function Genre(){
    const { id } = useParams();
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);
    };
    const [filter, setFilter] = useState("");
    const closeMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);	// Code pour fermer le menu
    };

    const [menuOpen0, setMenuOpen0] = useState(false);
    const [menuActive0, setMenuActive0] = useState(false);
    const toggleMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);
    };
    
    const closeMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);	// Code pour fermer le menu
    };

    const [menuOpen01, setMenuOpen01] = useState(false);
    const [menuActive01, setMenuActive01] = useState(false);
    const toggleMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);
    };
    
    const closeMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);	// Code pour fermer le menu
    };


    
    const [menuOpen011, setMenuOpen011] = useState(false);
    const [menuActive011, setMenuActive011] = useState(false);
    const toggleMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);
    };
    
    const closeMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);	// Code pour fermer le menu
    };
  
    const [agences, setAgences] = useState([]);

    const [agences0, setAgences0] = useState([]);
    

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {












            
    const loadScripts = () => {
        // Remove existing script elements added by the component
        const scriptElements = Array.from(document.getElementsByTagName('script'));
        const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
  
        componentScriptElements.forEach((script) => {
          document.body.removeChild(script);
        });
  
  
    
 
      const script1 = document.createElement('script');
      script1.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/jquery/js/jquery.min.js';
      script1.async = true;
  
      const script2 = document.createElement('script');
      script2.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/popper.js/js/popper.min.js';
      script2.async = true;

      const script3 = document.createElement('script');
      script3.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/bootstrap/js/bootstrap.min.js';
      script3.async = true;
  
     
      const script5 = document.createElement('script');
      script5.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
      script5.async = true;


      const script6 = document.createElement('script');
      script6.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/isotope-layout/js/isotope.pkgd.min.js';
      script6.async = true;

      const script8 = document.createElement('script');
      script8.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
      script8.async = true;

      const script7 = document.createElement('script');
      script7.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/slick-carousel/js/slick.min.js';
      script7.async = true;
      const script4 = document.createElement('script');
      script4.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/magnific-popup/js/jquery.magnific-popup.min.js';
      script4.async = true;

      const script9 = document.createElement('script');
      script9.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/sal.js/sal.js';
      script9.async = true;

      const script10 = document.createElement('script');
      script10.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/sal.js/sal.js';
      script10.async = true;

      const script11 = document.createElement('script');
      script11.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/mcustomscrollbar/jquery.mCustomScrollbar.concat.min.js';
      script11.async = true;

      const script12 = document.createElement('script');
      script12.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/assets/js/app.js';
      script12.async = true;
      const script13 = document.createElement('script');
      script13.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/select2/js/select2.min.js';
      script13.async = true;


      const script14 = document.createElement('script');
      script14.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/elevate-zoom/jquery.elevatezoom.js';
      script14.async = true;
      const script15 = document.createElement('script');
      script15.src = 'http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/dependencies/bootstrap-validator/js/validator.min.js';
      script15.async = true;


      document.body.appendChild(script1);
      document.body.appendChild(script2);
      document.body.appendChild(script3);
      document.body.appendChild(script8);
      document.body.appendChild(script5);
     
      document.body.appendChild(script6);
      document.body.appendChild(script7);
      document.body.appendChild(script9);


      document.body.appendChild(script4);
      document.body.appendChild(script10);
      document.body.appendChild(script11);


      document.body.appendChild(script13);

      document.body.appendChild(script14);


      document.body.appendChild(script15);

      document.body.appendChild(script12);
     
     
    };
      loadScripts();
  
  
      return () => {
        const scriptElements = Array.from(document.getElementsByTagName('script'));
        const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
  
        componentScriptElements.forEach((script) => {
          document.body.removeChild(script);
        });
      };


}, []);


useEffect(() => {
getAgences();
  


    const htmlElement = document.getElementById('wrapper');
    const htmlElement0 = document.getElementById('menu0');
    const htmlElement01 = document.getElementById('chat-box-modal');
    const htmlElement011 = document.getElementById('searchBtn');
    const htmlElement0111 = document.getElementById('users0');
    const htmlElement01111 = document.getElementById('users1');


    
    if (htmlElement) {
        if (menuActive) {
          htmlElement.classList.add('open');

          document.getElementById('menu1').classList.add('lg-menu-open');
        
        } else {
          htmlElement.classList.remove('open');
          document.getElementById('menu1').classList.remove('lg-menu-open');
         
        }
      }

      if (htmlElement0) {
        if (menuActive0) {
          htmlElement0.classList.add('chat-head-hide');

        
        
        } else {
          htmlElement0.classList.remove('chat-head-hide');
       
         
        }
      }

      if (htmlElement01) {
        if (menuActive01) {
          htmlElement01.classList.add('modal-show');

        
        
        } else {
          htmlElement01.classList.remove('modal-show');
       
         
        }

       
      }



      if (htmlElement011) {
      if (menuActive011) {
        htmlElement011.classList.add('show');

      
      
      } else {
        htmlElement011.classList.remove('show');
     
       
      }
    }

    
  
    

}, [menuActive,menuActive0,menuActive01,menuActive011]);

        
        function getAgences(){
          
         axios.get(`http://ascoma.jobs-conseil.tech/thezik/thezikweb/dist83/${id}`).then(function(response){
            console.log(response.data);
            setAgences(response.data);
            setIsLoading(false);
         });
        }

        function getAgences0(){
          
            axios.get('http://ascoma.jobs-conseil.tech/thezik/thezikweb/dist83/').then(function(response){
               console.log(response.data);
               setAgences0(response.data);
               setIsLoading(false);
              
            });
           }

        const handleFilterChange = (event) => {
            setFilter(event.target.value);
          };

          const filteredAgences0 = agences0.filter((agence0) => agence0.genre === id);

    return(
    <Fragment>

<a href="#wrapper" data-type="section-switch" className="scrollup">
        <i className="icofont-bubble-up"></i>
    </a>
   
   
    <div id="wrapper" className="wrapper">
      
    <header className="fixed-header">
            <div className="header-menu">
                <div className="navbar">
                    <div className="nav-item d-none d-sm-block">
                        <div className="header-logo bg-info">
                            <Link to='/Accueil'><img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/logothezik.png" style={{height:'60px'}} alt="Logo" /></Link>
                        </div>
                    </div>
                    <div className="nav-item nav-top-menu">
                        <nav id="dropdown" className="template-main-menu">
                            <ul className="menu-content">
                                <li className="header-nav-item">
                                    <Link to='/' className="menu-link active">Accueil</Link>
                                </li>
                              
                                <li className="header-nav-item">
                                            <Link to='/'>Clips vidéos</Link>
                                 </li>

                                 <li className="header-nav-item">
                                            <Link to='/Musics'>Clips mp3</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="nav-item header-control">
                        <div className="inline-item d-none d-md-block">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Recherchez ici......." />
                                <div className="input-group-append">
                                    <button className="submit-btn" type="button"><i className="icofont-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="inline-item d-flex align-items-center">
                            <div className="dropdown dropdown-cart">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-shopping-cart"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="item-heading">
                                        <h6 className="heading-title">Shopping Cart: <span>3</span></h6>
                                    </div>
                                    <div className="item-body">
                                        <div className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/cart_1.jpg" alt="Product" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title"><a href="#">Black Coffee Mug</a></h6>
                                                <div className="item-category">COFFEE MUGS</div>
                                                <div className="item-price">$29 x 1</div>
                                                <div className="btn-area">
                                                    <a href="#" className="close-btn"><i className="icofont-ui-close"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/cart_2.jpg" alt="Product" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title"><a href="#">Head Phone</a></h6>
                                                <div className="item-category">Assets</div>
                                                <div className="item-price">$15 x 1</div>
                                                <div className="btn-area">
                                                    <a href="#" className="close-btn"><i className="icofont-ui-close"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/cart_3.jpg" alt="Product" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title"><a href="#">Black Watch</a></h6>
                                                <div className="item-category">Watch</div>
                                                <div className="item-price">$59 x 1</div>
                                                <div className="btn-area">
                                                    <a href="#" className="close-btn"><i className="icofont-ui-close"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-footer">
                                        <a href="#" className="view-btn">View All Product</a>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown dropdown-friend">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-users-alt-4"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="item-heading">
                                        <h6 className="heading-title">Friend Requests</h6>
                                        <div className="heading-btn">
                                            <a href="#">Settings</a>
                                            <a href="#">Mark all as Read</a>
                                        </div>
                                    </div>
                                    <div className="item-body">
                                        <div className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_5.jpg" alt="Notify" />
                                                <span className="chat-status offline"></span>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title"><a href="#">Lily Zaman</a></h6>
                                                <p>4 in mutual friends</p>
                                                <div className="btn-area">
                                                    <a href="#" className="item-btn"><i className="icofont-plus"></i></a>
                                                    <a href="#" className="item-btn"><i className="icofont-minus"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_1.jpg" alt="Notify" />
                                                <span className="chat-status online"></span>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title"><a href="#">Ketty Rose</a></h6>
                                                <p>3 in mutual friends</p>
                                                <div className="btn-area">
                                                    <a href="#" className="item-btn"><i className="icofont-plus"></i></a>
                                                    <a href="#" className="item-btn"><i className="icofont-minus"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_8.jpg" alt="Notify" />
                                                <span className="chat-status online"></span>
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title"><a href="#">Rustom vai</a></h6>
                                                <p>6 in mutual friends</p>
                                                <div className="btn-area">
                                                    <a href="#" className="item-btn"><i className="icofont-plus"></i></a>
                                                    <a href="#" className="item-btn"><i className="icofont-minus"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-footer">
                                        <a href="#" className="view-btn">View All Friend Request</a>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown dropdown-message">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-speech-comments"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="item-heading">
                                        <h6 className="heading-title">Message</h6>
                                        <div className="heading-btn">
                                            <a href="#">Settings</a>
                                            <a href="#">Mark all as Read</a>
                                        </div>
                                    </div>
                                    <div className="item-body">
                                        <a href="#" className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/notifiy_1.png" alt="Notify" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title">Diana Jameson</h6>
                                                <div className="item-time">15 mins ago</div>
                                                <p>when are nknowen printer took galley of types ...</p>
                                            </div>
                                        </a>
                                        <a href="#" className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/notifiy_2.png" alt="Notify" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title">Quirty</h6>
                                                <div className="item-time">15 mins ago</div>
                                                <p>when are nknowen printer took galley of types ...</p>
                                            </div>
                                        </a>
                                        <a href="#" className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/notifiy_3.png" alt="Notify" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title">Zinia Jessy</h6>
                                                <div className="item-time">15 mins ago</div>
                                                <p>when are nknowen printer took galley of types ...</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="item-footer">
                                        <a href="#" className="view-btn">View All Messages</a>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown dropdown-notification">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-notification"></i><span className="notify-count">3</span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="item-heading">
                                        <h6 className="heading-title">Notifications</h6>
                                        <div className="heading-btn">
                                            <a href="#">Settings</a>
                                            <a href="#">Mark all as Read</a>
                                        </div>
                                    </div>
                                    <div className="item-body">
                                        <a href="#" className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/notifiy_1.png" alt="Notify" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title">Diana Jameson</h6>
                                                <div className="item-time">15 mins ago</div>
                                                <p>when are nknowen printer took galley of types ...</p>
                                            </div>
                                        </a>
                                        <a href="#" className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/notifiy_2.png" alt="Notify" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title">Quirty</h6>
                                                <div className="item-time">15 mins ago</div>
                                                <p>when are nknowen printer took galley of types ...</p>
                                            </div>
                                        </a>
                                        <a href="#" className="media">
                                            <div className="item-img">
                                                <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/notifiy_3.png" alt="Notify" />
                                            </div>
                                            <div className="media-body">
                                                <h6 className="item-title">Zinia Jessy</h6>
                                                <div className="item-time">15 mins ago</div>
                                                <p>when are nknowen printer took galley of types ...</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="item-footer">
                                        <a href="#" className="view-btn">View All Notification</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inline-item">
                            <div className="dropdown dropdown-admin">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <span className="media">
                                        <span className="item-img">
                                            <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_5.jpg" alt="Chat" />
                                            <span className="acc-verified"><i className="icofont-check"></i></span>
                                        </span>
                                        <span className="media-body">
                                            <span className="item-title">Rebeca</span>
                                        </span>
                                    </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="admin-options">
                                  
                                        <li><a href="login.html">Déconnexion</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
     
        <div className="fixed-sidebar" id="menu1">
            <div className="fixed-sidebar-left small-sidebar">
                <div className="sidebar-toggle">
                    <button onClick={toggleMenu} className="toggle-btn toggler-open">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className="sidebar-menu-wrap">
                    <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                        <ul className="side-menu">
                            <li><Link to='/Accueil' className="menu-link" data-toggle="tooltip" data-placement="right" title=" ACCUEIL"><i className="icofont-home"></i></Link></li>
                            <li><Link to='/Utilisateurs' className="menu-link" data-toggle="tooltip" data-placement="right" title="UTILISATEURS"><i className="icofont-users"></i></Link></li>
                            <li><Link to='/Videos' className="menu-link text-info" data-toggle="tooltip" data-placement="right" title="CLIPS-VIDEOS"><i className="icofont-play-alt-1"></i></Link></li>
                            <li><Link to='/Musics' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS MP3"><i className="icofont-music"></i></Link></li>
                            <li><Link to='/Compte' className="menu-link" data-toggle="tooltip" data-placement="right" title="MON COMPTE"><i className="icofont-user"></i></Link></li>
                            <li><Link to='/Apropos' className="menu-link" data-toggle="tooltip" data-placement="right" title="A PROPOS"><i className="icofont-question"></i></Link></li>
                            <li><a href="#" className="menu-link" data-toggle="tooltip" data-placement="right" title="DECONNEXION"><i className="icofont-power"></i></a></li>
                        
                        </ul>
                    </div>
                </div>
            </div>
            <div className="fixed-sidebar-left large-sidebar bg-info">
                <div className="sidebar-toggle">
                    <div className="sidebar-logo bg-info">
                        <a href="index.html"><img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/logothezik.png" style={{height:'50px'}} alt="Logo" /></a>
                    </div>
                    <button onClick={closeMenu} className="toggle-btn toggler-close">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className="sidebar-menu-wrap">
                    <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                        <ul className="side-menu">
                            <li><Link to='/Accueil' className="menu-link"><i className="icofont-home"></i><span className="menu-title">Accueil</span></Link></li>
                            <li><Link to='/Utilisateurs' className="menu-link"><i className="icofont-users"></i><span className="menu-title">Utilisateurs</span></Link></li>
                            <li><Link to='/Videos' className="menu-link active text-info"><i className="icofont-play-alt-1"></i><span className="menu-title active">Clips-vidéos</span></Link></li>
                            <li><Link to='/Musics' className="menu-link"><i className="icofont-music"></i><span className="menu-title">Clips mp3</span></Link></li>
                            <li><Link to='/Compte' className="menu-link"><i className="icofont-user"></i><span className="menu-title">Mon compte</span></Link></li>
                            <li><Link to='/Apropos' className="menu-link"><i className="icofont-question"></i><span className="menu-title">A propos</span></Link></li>
                            <li><a href="#" className="menu-link"><i className="icofont-power"></i><span className="menu-title">Deconnexion</span></a></li>
                           </ul>
                        
                    </div>
                </div>
            </div>
        </div>
      
        <div className="fixed-sidebar right" id='menu0'>
            <div className="fixed-sidebar-right small-sidebar">
                <div onClick={toggleMenu0} className="sidebar-toggle" id="chat-head-toggle">
                    <button onClick={closeMenu0} className="chat-icon">
                        <i className="icofont-speech-comments"></i>
                    </button>
                </div>
                <div className="sidebar-menu-wrap">
                    <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                        <ul className="user-chat-list">
                            <li onClick={toggleMenu01} className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_1.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_2.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_3.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_4.jpg" alt="chat" />
                                    <span className="chat-status online"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_5.jpg" alt="chat" />
                                    <span className="chat-status online"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_6.jpg" alt="chat" />
                                    <span className="chat-status online"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_7.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_8.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_9.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                            <li className="chat-item chat-open">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_10.jpg" alt="chat" />
                                    <span className="chat-status offline"></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
 
        <div className="page-content">

            <div className="container">
             
                <div className="banner-user">
                    <div className="banner-content">
                        <div className="media">
                            <div className="item-img">
                                <img style={{height:'115PX'}} src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/logothezik.png" alt="User" />
                            </div>
                            <div className="media-body">
                                <h3 className="item-title">THEZIK</h3>
                                <div className="item-subtitle">TOUS LES CLIPS-VIDEOS</div>
                                <ul className="item-social">
                                    <li><a href="#" className="bg-fb"><i className="icofont-facebook"></i></a></li>
                                    <li><a href="#" className="bg-twitter"><i className="icofont-twitter"></i></a></li>
                                    <li><a href="#" className="bg-dribble"><i className="icofont-dribbble"></i></a></li>
                                    <li><a href="#" className="bg-youtube"><i className="icofont-brand-youtube"></i></a></li>
                                    <li><a href="#" className="bg-behance"><i className="icofont-behance"></i></a></li>
                                </ul>
                                <ul className="user-meta">
                                    <li>Posts: <span>30</span></li>
                                    <li>Comments: <span>12</span></li>
                                    <li>Views: <span>1.2k</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block-box user-top-header">
                    <ul className="menu-list">
                        <li><Link to='/'>Tous</Link></li>
                        {agences0.map((agence0, key0) => (
                            <>
                             {agence0.genre === id ? (
      <li className='active' key={key0}>
        <Link to={`/Genres/${agence0.genre}`}>{agence0.genre}</Link>
      </li>
     
      ) : (
        <li key={key0}>
        <Link to={`/Genres/${agence0.genre}`}>{agence0.genre}</Link>
      </li>
      )}
       </>
      ))}
                       
                        <li>
                            <div className="dropdown">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    ...
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                {agences0
  .map((agence0, key00) => (
    <Link to={`/Genres/${agence0.genre}`} className="dropdown-item">{agence0.genre}</Link>
  ))}
                     
                                </div>
                            </div>
                        </li> 
                    </ul>
                </div>
                <div className="block-box user-search-bar justify-content-between">
                    <div className="box-item">
                        <div className="item-show-title">Total de <font color='teal'> {agences.length}</font> vidéos</div>
                    </div>
                    <div className="box-item search-filter">
                        <div className="dropdown">
                        
                            <input value={filter}
  onChange={handleFilterChange} placeholder='Rechercher une vidéo' type='text' className='form-control'/>
                        </div>
                    </div>
                </div>
                <div className="row gutters-20">


                {agences.filter((agence) =>
agence.artist.toLowerCase().includes(filter.toLowerCase()) ||
agence.titre.toLowerCase().includes(filter.toLowerCase()) ||

agence.datepub.toLowerCase().includes(filter.toLowerCase())
)
  
  .map((agence, key) => (

                    <div style={{borderRadius:'10 10 10 10'}} className="col-lg-4 col-md-6">
                        <div className="block-box user-blog">
                           
                            <div className="user-video">
                            <img style={{height:'250PX',width:'100%'}} src={`http://ascoma.jobs-conseil.tech/thezik/thezikweb/${agence.image}`} alt="Blog" />
                          
                            <div className="video-btn">
                                <a href={`http://ascoma.jobs-conseil.tech/thezik/thezikweb/${agence.mp4}`} className="popup-youtube play-icon">
                                    <i className="icofont-ui-play"></i>
                                </a>
                            </div>
                        </div>


                              
                            <div className="blog-content">
                                
                                <h3 className="blog-title"> <Link to={`/${agence.id}`}>{agence.artist}
                                <p> <h4 className='text-info'>{agence.titre}</h4></p></Link></h3>
                                <div className="blog-date"><i className="icofont-calendar"></i> {agence.datepub}</div>
                                <div className="blog-date"><i className="icofont-music"></i> {agence.taille2}</div>
                               
                            </div>
            
                        </div>
                   
					</div>

  
 
                  ))}             
				
				
              
					</div>
					
                <div className="load-more-post">
                {isLoading ? (
   
           <span align='center' style={{textAlign:'center'}} className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            
      
                  
                ) : ( 
                    <Link to='/Videos' className="item-btn"><i className="icofont-refresh"></i>Charger plus de vidéos</Link>
                )}
                </div>
            </div>
           
           



        </div>

        <div className="chat-conversion-box" id="chat-box-modal" tabindex="-1" aria-labelledby="chat-modal-label" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6 className="modal-title" id="chat-modal-label">Fahim Rahman <span className="online"></span></h6>
                        <div className="action-icon">
                            <button type="button" onClick={closeMenu01} className="close chat-close chat-open" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <ul className="chat-conversion">
                            <li className="chat-others">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_12.jpg" alt="Chat" />
                                </div>
                                <div className="author-text">
                                    <span>How are you Fahim vai ? Tommorow office will be your last day of Bachelor life.</span>
                                </div>
                            </li>
                            <li className="chat-you">
                                <div className="author-img">
                                    <img src="http://ascoma.jobs-conseil.tech/thezik/thezikweb/importe/media/figure/chat_11.jpg" alt="Chat" />
                                </div>
                                <div className="author-text">
                                    <span>hmm That's great</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <form>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Write your text here....." />
                                <div className="chat-plus-icon"><i className="icofont-plus-circle"></i></div>
                                <div className="file-attach-icon">
                                    <a href="#"><i className="icofont-slightly-smile"></i></a>
                                    <a href="#"><i className="icofont-camera"></i></a>
                                    <a href="#"><i className="icofont-image"></i></a>
                                    <a href="#"><i className="icofont-mic"></i></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Fragment>
    )

}

export default Genre;