import React, { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Ok = () => {
  const { transcript, resetTranscript, listening } = useSpeechRecognition({ continuous: true });

  useEffect(() => {
    if (transcript) {
      // Envoyer le texte transcrit au serveur PHP en utilisant une requête POST
      fetch('/search/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: transcript }), // Envoyez le texte transcrit dans le corps de la requête
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('La requête a échoué');
          }
          return response.json();
        })
        .then(data => {
          // Traiter les résultats reçus du serveur
          console.log(data);
        })
        .catch(error => {
          console.error('Erreur lors de la requête vers le serveur:', error);
        });

      resetTranscript(); // Réinitialise le texte transcrit après l'envoi
    }
  }, [transcript]); // Déclencher l'effet lorsque le texte transcrit change

  if (!SpeechRecognition.browserSupportsSpeechRecognition) {
    return <div>La reconnaissance vocale n'est pas prise en charge par votre navigateur.</div>;
  }

  return (
    <div>
      {!listening ? (
        <button onClick={SpeechRecognition.startListening}>Démarrer la reconnaissance vocale</button>
      ) : (
        <p>Écoute en cours...</p>
      )}
      <p>Texte transcrit : {transcript}</p>
    </div>
  );
};

export default Ok;
