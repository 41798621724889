import  React, { Fragment, useEffect,useRef } from 'react';
import axios from "axios";
import { useState } from "react";
import { useNavigate, Link } from 'react-router-dom';

import './icofont.min.css';




function Connexion(){

	const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false); // Add a new state variable for loading status

        useEffect(() => {












            
            const loadScripts = () => {
                // Remove existing script elements added by the component
                const scriptElements = Array.from(document.getElementsByTagName('script'));
                const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
          
                componentScriptElements.forEach((script) => {
                  document.body.removeChild(script);
                });
          
          
            
         
              const script1 = document.createElement('script');
              script1.src = 'https://thezik.org/importe/dependencies/jquery/js/jquery.min.js';
              script1.async = true;
          
              const script2 = document.createElement('script');
              script2.src = 'https://thezik.org/importe/dependencies/popper.js/js/popper.min.js';
              script2.async = true;
      
              const script3 = document.createElement('script');
              script3.src = 'https://thezik.org/importe/dependencies/bootstrap/js/bootstrap.min.js';
              script3.async = true;
          
             
              const script5 = document.createElement('script');
              script5.src = 'https://thezik.org/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
              script5.async = true;
      
      
              const script6 = document.createElement('script');
              script6.src = 'https://thezik.org/importe/dependencies/isotope-layout/js/isotope.pkgd.min.js';
              script6.async = true;
      
              const script8 = document.createElement('script');
              script8.src = 'https://thezik.org/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
              script8.async = true;
      
              const script7 = document.createElement('script');
              script7.src = 'https://thezik.org/importe/dependencies/slick-carousel/js/slick.min.js';
              script7.async = true;
              const script4 = document.createElement('script');
              script4.src = 'https://thezik.org/importe/dependencies/magnific-popup/js/jquery.magnific-popup.min.js';
              script4.async = true;
      
              const script9 = document.createElement('script');
              script9.src = 'https://thezik.org/importe/dependencies/sal.js/sal.js';
              script9.async = true;
      
              const script10 = document.createElement('script');
              script10.src = 'https://thezik.org/importe/dependencies/sal.js/sal.js';
              script10.async = true;
      
              const script11 = document.createElement('script');
              script11.src = 'https://thezik.org/importe/dependencies/mcustomscrollbar/jquery.mCustomScrollbar.concat.min.js';
              script11.async = true;
      
              const script12 = document.createElement('script');
              script12.src = 'https://thezik.org/importe/assets/js/app.js';
              script12.async = true;
              const script13 = document.createElement('script');
              script13.src = 'https://thezik.org/importe/dependencies/select2/js/select2.min.js';
              script13.async = true;
      
      
              const script14 = document.createElement('script');
              script14.src = 'https://thezik.org/importe/dependencies/elevate-zoom/jquery.elevatezoom.js';
              script14.async = true;
              const script15 = document.createElement('script');
              script15.src = 'https://thezik.org/importe/dependencies/bootstrap-validator/js/validator.min.js';
              script15.async = true;
      
      
              document.body.appendChild(script1);
              document.body.appendChild(script2);
              document.body.appendChild(script3);
              document.body.appendChild(script8);
              document.body.appendChild(script5);
             
              document.body.appendChild(script6);
              document.body.appendChild(script7);
              document.body.appendChild(script9);
      
      
              document.body.appendChild(script4);
              document.body.appendChild(script10);
              document.body.appendChild(script11);
      
      
              document.body.appendChild(script13);
      
              document.body.appendChild(script14);
      
      
              document.body.appendChild(script15);
      
              document.body.appendChild(script12);
             
             
            };
              loadScripts();
          
          
              return () => {
                const scriptElements = Array.from(document.getElementsByTagName('script'));
                const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
          
                componentScriptElements.forEach((script) => {
                  document.body.removeChild(script);
                });
              };
        

        }, []);


   

        let navigate =useNavigate();
        const [user, setUser]=useState({
          email_agent: '',
          pass_agent:''
      
        })
        const handleChange = (e) => {
      
          setUser(({...user, [e.target.name]: e.target.value}));
      
      }
      
      const [user1, setUser1]=useState({
        email_agent1: '',
        fname: '',
        lname: '',
        pass_agent1:''
    
      })
      const handleChange1 = (e) => {
    
        setUser1(({...user1, [e.target.name]: e.target.value}));
    
    }
      
      const submitForm=(e) => {
      e.preventDefault();
      const sendData = {
          email_agent:user.email_agent,
          pass_agent:user.pass_agent
      
      }
      
          setIsLoading(true);
      console.log(sendData);
      axios.post('/login1/index.php',sendData).then((result)=>{
       if (result.data.Status === '200'){
      window.localStorage.setItem('email', result.data.email_agent);
      window.localStorage.setItem('nom', result.data.nom_agent);
      window.localStorage.setItem('prenom', result.data.prenom_agent);
      window.localStorage.setItem('id', result.data.id_agent);
      window.localStorage.setItem('pass', result.data.pass_agent);
      window.localStorage.setItem('profil', result.data.profil);
      console.log(result.data);
     
         navigate('/Accueil')
         
       } else {
          setSuccessMessage("Echec d'authentification! ");
      
       }
      }
      
      )
      
      }
    
    
      const submitForm1=(e) => {
        e.preventDefault();
        const sendData = {
            email_agent:user1.email_agent1,
            lname:user1.lname,
            fname:user1.fname,
            pass_agent:user1.pass_agent1
        
        }
        
            setIsLoading(true);
       
        axios.post('https://thezik.org/login11/index.php',sendData).then((result)=>{
         if (result.data.Status === '200'){
        window.localStorage.setItem('email', result.data.email_agent);
        window.localStorage.setItem('nom', result.data.nom_agent);
        window.localStorage.setItem('prenom', result.data.prenom_agent);
        window.localStorage.setItem('id', result.data.id_agent);
        window.localStorage.setItem('pass', result.data.pass_agent);
        window.localStorage.setItem('profil', result.data.profil);
        console.log(sendData);
           navigate('/Accueil')
           
         } else {
            setSuccessMessage("Email existant ! ");
        
         }
        }
        
        )
        
        }
    return(


    <Fragment>

<a href="#wrapper" data-type="section-switch" className="scrollup">
        <i className="icofont-bubble-up"></i>
    </a>
    
    <div id="wrapper" className="wrapper overflow-hidden">

      
        <div className="login-page-wrap">
            <div className="content-wrap">
                <div className="login-content">
               
             
                    <div className="login-form-wrap">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" href="#login-tab" role="tab" aria-selected="true"><i className="icofont-users-alt-4"></i> Connexion </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-toggle="tab" href="#registration-tab" role="tab" aria-selected="false"><i className="icofont-download"></i> Créer compte</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane login-tab fade show active" id="login-tab" role="tabpanel">
                            <a href="#"><img style={{height:'70px'}} src="https://thezik.org/importe/media/logothezik.png" alt="logo" /></a>
                  
                                <h3 className="item-title">Se connecter</h3>
                                {isLoading ? (
    <span style={{marginBottom:'10px'}} className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
  ) : (
    <span></span>
  )}

									{successMessage && (
                <div className="alert alert-danger" role="alert">
                  {successMessage}
                </div>

                
              )} 
                                <form onSubmit={submitForm}>
                                    <div className="form-group">
                                        <input type="email" className="form-control" name='email_agent' value={user.email_agent} onChange={handleChange} autocomplete="off" placeholder="Email" />
                                    </div>
                                    <div className="form-group">
                                        <input name='pass_agent' value={user.pass_agent} onChange={handleChange} type="password" autocomplete="off" className="form-control" placeholder="Mot de passe" />
                                    </div>
                                    <div className="form-group reset-password text-info">
                                        <Link><div className='text-info'>* Réinitialiser mot de passe</div></Link>
                                    </div>
                                    <div class="form-group mb-4">
                                        <Link to='/Accueil' class="form-check text-primary">
                                            Accéder sans compte <i className='icofont-arrow-right'></i>
                                        </Link>
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" name="login-btn" className="submit-btn" value="Connexion" />
                                    </div>
                                </form>
                            
                            </div>
                            <div className="tab-pane registration-tab fade" id="registration-tab" role="tabpanel">
                                <h3 className="item-title">Créer un compte</h3>
                                <form onSubmit={submitForm1}>
                                    <div className="form-group">
                                        <input type="text" name="fname" value={user1.fname} onChange={handleChange1} className="form-control" placeholder="Nom" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="lname" value={user1.lname} onChange={handleChange1} className="form-control" placeholder="Prenom" />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" name="email_agent1" value={user1.email_agent1} onChange={handleChange1} className="form-control" placeholder="E-mail" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="pass_agent1" value={user1.pass_agent1} onChange={handleChange1} className="form-control" placeholder="Mot de passe" />
                                    </div>
                                    
                                    
                                    <div className="form-group">
                                        <input type="submit" name="registration" className="submit-btn" value="Créer compte" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>

        
        <div id="header-search" className="header-search">
            <button type="button" className="close">×</button>
            <form className="header-search-form">
                <input type="search" value="" placeholder="Search here..." />
                <button type="submit" className="search-btn">
                    <i className="flaticon-search"></i>
                </button>
            </form>
        </div>
    </div>
</Fragment>
    )

}

export default Connexion;