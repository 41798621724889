import React, { Fragment, useEffect, useState,useRef } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './icofont.min.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function downloadFile(fileUrl, fileName, onProgress) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';
  
    xhr.addEventListener('progress', event => {
      if (event.lengthComputable) {
        const percentComplete = ((event.loaded / event.total) * 100).toString().slice(0, 4);
       
        onProgress(percentComplete);
      }
    });
  
    xhr.addEventListener('load', event => {
      if (xhr.status === 200) {
        onProgress(100);
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Erreur lors du téléchargement du fichier:', xhr.status);
      }
    });
  
    xhr.send();
  }
  

function Apropos(){
    const [isLoading0, setIsLoading0] = useState(true);
    const [agences7, setAgences7] = useState([]);
    const [agences5, setAgences5] = useState([]);
    const [agences9, setAgences9] = useState([]);
    const audioRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
  
          
  const [user, setUser]=useState({
    message: '',
    idr:''

  })
          const handleChangeu = (e) => {

            setUser(({...user, [e.target.name]: e.target.value}));
        
        }

          const submitFormu=(e) => {
            e.preventDefault();
            const sendData = {
                message:user.message,
                idr:localStorage.getItem('ids')
            
            }
            
                setIsLoading(true);
            console.log(sendData);
            axios.post(`https://thezik.org/dist877/${localStorage.getItem('id')}`,sendData).then((result)=>{
                audioRef.current.play();
            document.getElementById('messs').value='';
            
            
            }
            
            )
            
            }
  const handleClick2 = (dest, profil, nom, prenom) => {
    
    setIsLoading0(true);
    document.getElementById('chat-modal-label').innerHTML=prenom +' '+ nom;
    document.getElementById('idr').value=dest;
    window.localStorage.setItem('ids', dest);
    
    document.getElementById('prof').src='https://thezik.org/importe/media/' + profil;
    // const formData11 = new FormData();
    // formData11.append('imageUrl', imageUrl1);
    // formData11.append('additionalValue', additionalValue1);
    // // Envoyer l'URL de l'image et la valeur supplémentaire en tant que POST
    // axios.post("https://thezik.org/dist87/", formData11)
    //   .then(response => {
       
    //     document.getElementById('oii').click();
    //     audioRef.current.play();
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
  };
        const progressBarStyles = {
          width: '90%',
          height: '10px',
          marginLeft:'5px',
          marginRight:'5px',
          backgroundColor: '#f1f1f1',
          borderRadius: '4px',
          overflow: 'hidden',
        };
      
      
        const [isMobile, setIsMobile] = useState(false);

        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Définissez la largeur de l'écran à laquelle vous souhaitez réagir
          };
      
          window.addEventListener('resize', handleResize);
          handleResize(); // Vérifiez l'état initial de la largeur de l'écran
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, []);  

        
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);
    };
    const [filter, setFilter] = useState("");
    const closeMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);	// Code pour fermer le menu
    };

    const [menuOpen0, setMenuOpen0] = useState(false);
    const [menuActive0, setMenuActive0] = useState(false);
    const toggleMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);
    };
    
    const closeMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);	// Code pour fermer le menu
    };

    const [menuOpen01, setMenuOpen01] = useState(false);
    const [menuActive01, setMenuActive01] = useState(false);
    const toggleMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);
    };
    
    const closeMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);	// Code pour fermer le menu
    };


    
    const [menuOpen011, setMenuOpen011] = useState(false);
    const [menuActive011, setMenuActive011] = useState(false);
    const toggleMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);
    };
    
    const closeMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);	// Code pour fermer le menu
    };

    const [inputs, setInputs] = useState([]);
    const { id } = useParams();
    const [progress, setProgress] = useState(0);

    useEffect(() => {












            
        const loadScripts = () => {
            // Remove existing script elements added by the component
            const scriptElements = Array.from(document.getElementsByTagName('script'));
            const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
      
            componentScriptElements.forEach((script) => {
              document.body.removeChild(script);
            });
      
      
        
     
          const script1 = document.createElement('script');
          script1.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/jquery/js/jquery.min.js';
          script1.async = true;
      
          const script2 = document.createElement('script');
          script2.src = 'http:/thezik.wuaze.com/thezik/thezikweb/importe/dependencies/popper.js/js/popper.min.js';
          script2.async = true;
  
          const script3 = document.createElement('script');
          script3.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/bootstrap/js/bootstrap.min.js';
          script3.async = true;
      
         
          const script5 = document.createElement('script');
          script5.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
          script5.async = true;
  
  
          const script6 = document.createElement('script');
          script6.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/isotope-layout/js/isotope.pkgd.min.js';
          script6.async = true;
  
          const script8 = document.createElement('script');
          script8.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
          script8.async = true;
  
          const script7 = document.createElement('script');
          script7.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/slick-carousel/js/slick.min.js';
          script7.async = true;
          const script4 = document.createElement('script');
          script4.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/magnific-popup/js/jquery.magnific-popup.min.js';
          script4.async = true;
  
          const script9 = document.createElement('script');
          script9.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/sal.js/sal.js';
          script9.async = true;
  
          const script10 = document.createElement('script');
          script10.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/sal.js/sal.js';
          script10.async = true;
  
          const script11 = document.createElement('script');
          script11.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/mcustomscrollbar/jquery.mCustomScrollbar.concat.min.js';
          script11.async = true;
  
          const script12 = document.createElement('script');
          script12.src = 'https://thezik.org/thezik/thezikweb/importe/assets/js/app.js';
          script12.async = true;
          const script13 = document.createElement('script');
          script13.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/select2/js/select2.min.js';
          script13.async = true;
  
  
          const script14 = document.createElement('script');
          script14.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/elevate-zoom/jquery.elevatezoom.js';
          script14.async = true;
          const script15 = document.createElement('script');
          script15.src = 'https://thezik.org/thezik/thezikweb/importe/dependencies/bootstrap-validator/js/validator.min.js';
          script15.async = true;
  
  
          document.body.appendChild(script1);
          document.body.appendChild(script2);
          document.body.appendChild(script3);
          document.body.appendChild(script8);
          document.body.appendChild(script5);
         
          document.body.appendChild(script6);
          document.body.appendChild(script7);
          document.body.appendChild(script9);
  
  
          document.body.appendChild(script4);
          document.body.appendChild(script10);
          document.body.appendChild(script11);
  
  
          document.body.appendChild(script13);
  
          document.body.appendChild(script14);
  
  
          document.body.appendChild(script15);
  
          document.body.appendChild(script12);
         
         
        };
          loadScripts();
      
      
          return () => {
            const scriptElements = Array.from(document.getElementsByTagName('script'));
            const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
      
            componentScriptElements.forEach((script) => {
              document.body.removeChild(script);
            });
          };
    

    }, []);


useEffect(() => {
    getAgences();
      
    
 
        const htmlElement = document.getElementById('wrapper');
        const htmlElement0 = document.getElementById('menu0');
        const htmlElement01 = document.getElementById('chat-box-modal');
        const htmlElement011 = document.getElementById('searchBtn');
        const htmlElement0111 = document.getElementById('users0');
        const htmlElement01111 = document.getElementById('users1');


        
        if (htmlElement) {
            if (menuActive) {
              htmlElement.classList.add('open');

              document.getElementById('menu1').classList.add('lg-menu-open');
            
            } else {
              htmlElement.classList.remove('open');
              document.getElementById('menu1').classList.remove('lg-menu-open');
             
            }
          }

          if (htmlElement0) {
            if (menuActive0) {
              htmlElement0.classList.add('chat-head-hide');

            
            
            } else {
              htmlElement0.classList.remove('chat-head-hide');
           
             
            }
          }

          if (htmlElement01) {
            if (menuActive01) {
              htmlElement01.classList.add('modal-show');

            
            
            } else {
              htmlElement01.classList.remove('modal-show');
           
             
            }

           
          }



          if (htmlElement011) {
          if (menuActive011) {
            htmlElement011.classList.add('show');

          
          
          } else {
            htmlElement011.classList.remove('show');
         
           
          }
        }

        
      
        
    
    }, [menuActive,menuActive0,menuActive01,menuActive011]);
    
        function getAgences(){
          
         axios.get(`https://thezik.org/thezik/thezikweb/dist810/${id}`).then(function(response){
            console.log(response.data);
            setInputs(response.data);
           
         });
        }
      
            const handleDownload = () => {
              const fileUrl = `https://thezik.org/thezik/thezikweb/${inputs.mp3}`;
              const fileName = `${inputs.mp3}`;
              downloadFile(fileUrl, fileName, setProgress);
            };
        

            const handleShareOnWhatsApp = () => {
                const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Venez voir cette publication intéressante !');
    const whatsappUrl = `https://api.whatsapp.com/send?text=${text}%20${url}`;
    window.open(whatsappUrl, '_blank');
              };
         
              
              let navigate =useNavigate();
   

              const logOut = () => {
                localStorage.removeItem('id');
               
                localStorage.clear();
                navigate('/');
              };

                const [capturedText, setCapturedText] = useState(''); // État local pour stocker le texte transcrit
              const [searchResults, setSearchResults] = useState([]); // État local pour stocker les résultats de recherche
            
              const { transcript, resetTranscript, listening } = useSpeechRecognition({ continuous: true });
            
              useEffect(() => {
                if (transcript) {
                  setCapturedText(transcript); // Mettre à jour l'état local avec le texte transcrit
                }
              }, [transcript]);
            
              useEffect(() => {
                if (capturedText) {
                  // Envoyer le texte transcrit au serveur PHP en utilisant une requête POST
               
                  const sendData = {
                    query:capturedText
                
                }
                axios.post('/search/',sendData).then((result)=>{
                  
                
                  setSearchResults(result.data); // Mettre à jour l'état local avec les résultats de recherche
      
              });
                  resetTranscript(); // Réinitialise le texte transcrit après l'envoi
                }
              }, [capturedText]);
            
              if (!SpeechRecognition.browserSupportsSpeechRecognition) {
                return <div>La reconnaissance vocale n'est pas prise en charge par votre navigateur.</div>;
              }
             
    return(
    <Fragment>

      <audio style={{display:'none'}} ref={audioRef} src="./assets/media/success.mp3" />

    <div id="wrapper" className="wrapper">
      
      <header className="fixed-header">
              <div className="header-menu">
              <div className="navbar">
                      <div className="nav-item d-none d-sm-block">
                          <div className="header-logo bg-info">
                              <Link to='/'><img src="/importe/media/logothezik.png" style={{height:'60px'}} alt="Logo" /></Link>
                          </div>
                      </div>
                      <div className="nav-item nav-top-menu">
                          <nav id="dropdown" className="template-main-menu">
                              <ul className="menu-content">
                                  <li className="header-nav-item">
                                      <Link to='/' className="menu-link active">Accueil</Link>
                                  </li>
                                
                                  <li className="header-nav-item">
                                              <Link to='/Videos'>Clips vidéos</Link>
                                   </li>
  
                                   <li className="header-nav-item">
                                              <Link to='/Musics'>Clips mp3</Link>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                      <div className="nav-item header-control">
  
              
  
                          <div className="inline-item">
                              <div className="dropdown dropdown-admin">
                                  <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                      <span className="media">
                                      {!localStorage.getItem('id') ? (    
                          <>
                                          <span style={{borderRadius:'100%'}} className="item-img bg-white">
                                         
  
  
  
                                              <img src={`/importe/media/logothezik.png`} style={{height:'44PX',width:'44px'}} alt="Chat" />
                                              <span className="acc-verified"><i className="icofont-check"></i></span>
                                          </span>    
                                          <span className="media-body">
                                              <span className="item-title">THEZIK</span>
                                          </span>
                                              </>              
                                  ):(
                                    <>
                                   <span style={{borderRadius:'100%'}} className="item-img bg-white">
                                         
                                     <img src={`/importe/media/${localStorage.getItem('profil')}`} style={{height:'44PX',width:'44px'}} alt="Chat" />
                                             
                                             <span className="acc-verified"><i className="icofont-check"></i></span>
                                          </span> 
                                          <span className="media-body">
                                              <span className="item-title">{localStorage.getItem('prenom')}</span>
                                          </span>   
                                              </>
                                  )  }         
                                        
                                             
                                          
                                      </span>
                                  </button>
                                  {localStorage.getItem('id') ? (    
                          <>
                                  <div className="dropdown-menu dropdown-menu-right">
                                      <ul className="admin-options">
                                      <li><Link to='/Compte'>Mon compte</Link></li>
                                    
                                        
                                      </ul>
                                  </div>
  </>):(
      <></>
  )}
  
                              </div>
                          </div>
                       
                     
  
  
                      </div>
                  </div>
                  
              </div>
          </header>
       
          <div className="fixed-sidebar" id="menu1">
              <div className="fixed-sidebar-left small-sidebar">
                  <div className="sidebar-toggle">
                      <button onClick={toggleMenu} className="toggle-btn toggler-open">
                          <span></span>
                          <span></span>
                          <span></span>
                      </button>
                  </div>
                  <div className="sidebar-menu-wrap">
                      <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                          <ul className="side-menu">
                              <li><Link to='/' className="menu-link" data-toggle="tooltip" data-placement="right" title=" ACCUEIL"><i className="icofont-home"></i></Link></li>
                              <li><Link to='/Videos' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS-VIDEOS"><i className="icofont-play-alt-1"></i></Link></li>
                              <li><Link to='/Musics' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS MP3"><i className="icofont-music"></i></Link></li>
                              {localStorage.getItem('id') ? (    
                         <li><Link to='/Compte' className="menu-link" data-toggle="tooltip" data-placement="right" title="MON COMPTE"><i className="icofont-user"></i></Link></li>
                         ):(
                            <></>
                          )}
                              <li><Link to='/Apropos' className="menu-link text-info" data-toggle="tooltip" data-placement="right" title="A PROPOS"><i className="icofont-question"></i></Link></li>
                              {localStorage.getItem('id') ? (    
                          
                          <li><Link to='/' onClick={logOut} className="menu-link" data-toggle="tooltip" data-placement="right" title="DECONNEXION"><i className="icofont-power"></i></Link></li>
                            ):(
                              <li><Link to='/Login' className="menu-link" data-toggle="tooltip" data-placement="right" title="CONNEXION"><i className="icofont-power"></i></Link></li>
                          
                          )}
                             
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="fixed-sidebar-left large-sidebar bg-info">
                  <div className="sidebar-toggle">
                      <div className="sidebar-logo bg-info">
                          <a href="#"><img src="/importe/media/logothezik.png" style={{height:'50px'}} alt="Logo" /></a>
                      </div>
                      <button onClick={closeMenu} className="toggle-btn toggler-close">
                          <span></span>
                          <span></span>
                          <span></span>
                      </button>
                  </div>
                  <div className="sidebar-menu-wrap">
                      <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                          <ul className="side-menu">
                              <li><Link to='/' className="menu-link"><i className="icofont-home"></i><span className="menu-title">Accueil</span></Link></li>
                              <li><Link to='/Videos' className="menu-link active"><i className="icofont-play-alt-1"></i><span className="menu-title active">Clips-vidéos</span></Link></li>
                              <li><Link to='/Musics' className="menu-link"><i className="icofont-music"></i><span className="menu-title">Clips mp3</span></Link></li>
                              {localStorage.getItem('id') ? (    
                          
                          <li><Link to='/Compte' className="menu-link"><i className="icofont-user"></i><span className="menu-title">Mon compte</span></Link></li>
                          ):(
                            <></>
                          )}
                               <li><Link to='/Apropos' className="menu-link"><i className="icofont-question"></i><span className="menu-title">A propos</span></Link></li>
                              {localStorage.getItem('id') ? (    
                          
                              <li><Link to='/' onClick={logOut} className="menu-link"><i className="icofont-power"></i><span className="m enu-title">Deconnexion</span></Link></li>
                              ):(
                                <li><Link to='/Login' className="menu-link"><i className="icofont-power"></i><span className="menu-title">Connexion</span></Link></li>
                            
                              )}
                             </ul>
                          
                      </div>
                  </div>
              </div>
          </div>
        
          <div className="fixed-sidebar right" id='menu0'>
              <div className="fixed-sidebar-right small-sidebar">
                  <div className="sidebar-toggle" id="chat-head-toggle">
                      <button className="chat-icon">
                      {!listening ? (
                        <>
                      
                          
                        <i onClick={SpeechRecognition.startListening} className='icofont-audio'></i>
                      </>
              ) : (
                <img style={{height:'30PX',borderRadius:'100%'}} src='/assets/media/au.gif'/>
                   
      )}
                        
                      </button>
                  </div>
                  {/* <div className="sidebar-menu-wrap">
                      <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                      {searchResults.length > 0 && (
                          <ul className="user-chat-list">
                         {searchResults.map(resultt => (
                          <>
                           {resultt.mp3!='' && (
                          <li key={resultt.id} className="chat-item chat-open">
                            

                              <div className="user-video">
                            <div align='center'>  <img style={{height:'44.5PX',width:'44.5PX'}} src={`/${resultt.image}`} alt="chat" />
                                   </div>
                      
                        <div className="video-btn">
                       
                        <Link to={`/Musics/${resultt.id}`} className="play-icon" style={{background:'transparent'}}>
                                <i style={{}} className="icofont-ui-music"></i>
                            </Link>
                       
                        </div>
                    </div>


                          </li>
                          
                          )}

                          {resultt.mp4!='' && (
                         <li key={resultt.id} className="chat-item chat-open">
                           

                             <div className="user-video">
                           <div align='center'>  <img style={{height:'44.5PX',width:'44.5PX'}} src={`/${resultt.image}`} alt="chat" />
                                  </div>
                     
                       <div className="video-btn">
                      
                       <Link to={`/${resultt.id}`} className="play-icon" style={{background:'transparent'}}>
                               <i style={{}} className="icofont-ui-play"></i>
                           </Link>
                      
                       </div>
                   </div>


                         </li>
                         
                         )}</>

                         ))}

                      </ul>
    
      
      )}
                        
                      </div>
                  </div> */}
              </div>
          </div>
 
        <div className="page-content">

        <div className="container">
        {searchResults.length > 0 && (
          <>
          <div className="block-box load-more-btn col-lg-12">
            
          <a href="https://thezik.org/Apropos" className="item-btn"><i className="icofont-trash"></i>Effacer le résultat</a>
      </div>
      
      {!isMobile ? 
                    <>
      <div id="user-view" className="user-grid-view">

        <div className="row gutters-20">





      
          
 {searchResults.map(resultt => (
                          <>
                           {resultt.mp4!='' && (
                            <>
    
<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img" style={{height:'200PX'}}>
                                        </div>
                                    <div className="profile-img user-video bg-warning">
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-play"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h4 className=" "> <Link style={{color:'black'}} className="" to={`/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-primary" style={{fontSize:'17PX'}}>{resultt.taille2}</span><br />

                                 </Link>
                                </h4>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                           )}

{resultt.mp3!='' && (
                            <>
     

<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                       </div>
                                    <div className="profile-img user-video bg-warning" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/Musics/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-music"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h4 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/Musics/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-primary" style={{fontSize:'17PX'}}>{resultt.taille}</span><br />

                                 </Link>
                                </h4>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                           )}
                            </>
                           ))}
          </div></div>
          
          </>:
           <>
           <div id="user-view" className="user-list-view">

<div className="row gutters-20">






  
{searchResults.map(resultt => (
                  <>
                   {resultt.mp4!='' && (
                    <>


<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                        <img style={{height:'96PX',width:'277PX'}} src={`/${resultt.image}`} alt="cover" />
                                    </div>
                                    <div className="profile-img user-video" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-play"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h5 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-warning" style={{fontSize:'15PX'}}>{resultt.taille2}</span><br />

                                 </Link>
                                </h5>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                   )}

{resultt.mp3!='' && (
                    <>


<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                             </div>
                                    <div className="profile-img user-video" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/Musics/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-music"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h5 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/Musics/${resultt.id}`}>{resultt.artist.length > 12
                    ? `${resultt.artist.slice(0, 12)}...`
                    : resultt.artist}
                               <br />
                               <span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-warning" style={{fontSize:'15PX'}}>{resultt.taille}</span><br />

                                 </Link>
                                </h5>
                                   </div>
                                </div>
                              
                            </div>
                        </div>


</>
                   )}
                    </>
                   ))}
  </div></div>
           </>}   </>
        )}
        <section style={{marginTop:'-90PX'}} className="section team-circle">
            <div className="container position-relative">
                <div className="section-heading">
                    <h2 className="item-title">A propos de nous</h2>
                    <font style={{fontSize:'18PX'}}>
                    <p>
                   Nous sommes une organisation qui promouvoit les artiste nationaux et internationaux en publiant leurs musiques et en faisant leurs publicités.     

                    </p><br />
                    <p style={{color:'teal'}}>
                   THEZIK donne la possibilité aux artistes de se faire connaitre aussi bien sur le plan national qu'international et aux utilisateurs en général, de pouvoir commenter, écouter, partager et télécharger de la musique.
                    </p><br />

                    <p>
                   Contactez-nous afin de faire publier vos clips.
                    </p></font>
                    
                    <div className="see-all-btn">
                    <a href="#" className="button-slide">
                       
                    </a>
                </div>
                
                </div>
                
              
            </div>
        </section>
            </div>
            
        



        </div>

        <div className="chat-conversion-box" id="chat-box-modal" tabIndex="-1" aria-labelledby="chat-modal-label" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <img style={{height:'35PX',width:'35PX',borderRadius:'100%'}} id='prof' alt="Chat" />
      
                        <h6 className="modal-title" id="chat-modal-label"> <span className="online"></span></h6>
                        <div className="action-icon">
                        <button className="chat-shrink"><i className="icofont-minus"></i></button>
                            <button type="button" onClick={closeMenu01} className="close chat-close chat-open" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <ul className="chat-conversion" style={{height:'200PX',overflowY:'auto'}}>
                        {isLoading0 ? (
              <div align='center'>             
    <span style={{marginBottom:'10px'}} className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
    </div> 
  ) : (
    <> 
                        {agences7
 
 .map((agence7, key) => (    
    <>     
    {agence7.destinateur != localStorage.getItem('id')?(  
                            <li className="chat-others">
                                <div className="author-img">
                                <img style={{height:'30PX',width:'30PX'}} src={`https://thezik.org/importe/media/${agence7.image}`} alt="Chat" />
                             </div>
                                <div className="author-text">
                                    <span>{agence7.message}</span>
                                </div>
                            </li>
                            ):(
                                <>
                                <li className="chat-you">
                                <div className="author-img">
                                    <img style={{height:'30PX',width:'30PX'}} src={`https://thezik.org/importe/media/${agence7.image}`} alt="Chat" />
                                </div>
                                <div className="author-text">
                                    <span>{agence7.message}</span>
                                </div>
                            </li>
                                </>
                            )} 
                            </> 
                           
 ))}
        </>                    
            
  )}               
                           
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <form onSubmit={submitFormu}>
                            <div className="form-group">

 <input type="number" id='idr' style={{display:'none'}} name='idr' onChange={handleChangeu} className="form-control" />
                               

                                <input name='message'id='messs' onChange={handleChangeu} type="text" className="form-control" autoComplete='off' placeholder="Tapez votre message....." />
                                <div className="chat-plus-icon"><i className="icofont-plus-circle"></i></div>
                                <div className="file-attach-icon">
                                    <a href="#"><i className="icofont-slightly-smile"></i></a>
                                    <a href="#"><i className="icofont-camera"></i></a>
                                    <a href="#"><i className="icofont-image"></i></a>
                                    <a href="#"><i className="icofont-mic"></i></a>
                                </div>
                                <button style={{position:'absolute',top:'7PX',right:'-20px',borderRadius:'100%'}} className='btn btn-primary btn-sm sm' type='submit'><i className="icofont-arrow-right"></i></button>
                               
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>



    </div>
</Fragment>
    )

}

export default Apropos;