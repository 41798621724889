import React, { Fragment, useEffect, useState,useRef } from 'react';
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './icofont.min.css';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import annyang from 'annyang';

function downloadFile(fileUrl, fileName, onProgress) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUrl, true);
    xhr.responseType = 'blob';
  
    xhr.addEventListener('progress', event => {
      if (event.lengthComputable) {
        const percentComplete = ((event.loaded / event.total) * 100).toString().slice(0, 4);
       
        onProgress(percentComplete);
      }
    });
  
    xhr.addEventListener('load', event => {
      if (xhr.status === 200) {
        onProgress(100);
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        window.URL.revokeObjectURL(url);
      } else {
        console.error('Erreur lors du téléchargement du fichier:', xhr.status);
      }
    });
  
    xhr.send();
  }
  

function Details(){
    const [isLoading0, setIsLoading0] = useState(true);
    const [agences7, setAgences7] = useState([]);
    const [agences5, setAgences5] = useState([]);
    const [agences9, setAgences9] = useState([]);
    const audioRef = useRef(null);
    const [isMobile, setIsMobile] = useState(false);
    const [capturedText, setCapturedText] = useState(''); // État local pour stocker le texte transcrit
    const [searchResults, setSearchResults] = useState([]); // État local pour stocker les résultats de recherche
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Définissez la largeur de l'écran à laquelle vous souhaitez réagir
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Vérifiez l'état initial de la largeur de l'écran
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const [isLoading, setIsLoading] = useState(true);
    


  
          

          
  const [user, setUser]=useState({
    message: '',
    idr:''

  })
          const handleChangeu = (e) => {

            setUser(({...user, [e.target.name]: e.target.value}));
        
        }

        
 
        const progressBarStyles = {
          width: '90%',
          height: '10px',
          marginLeft:'5px',
          marginRight:'5px',
          backgroundColor: '#f1f1f1',
          borderRadius: '4px',
          overflow: 'hidden',
        };
      
      

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);
    };
    const [filter, setFilter] = useState("");
    const closeMenu = () => {
      setMenuOpen(!menuOpen);
      setMenuActive(!menuOpen);	// Code pour fermer le menu
    };

    const [menuOpen0, setMenuOpen0] = useState(false);
    const [menuActive0, setMenuActive0] = useState(false);
    const toggleMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);
    };
    
    const closeMenu0 = () => {
      setMenuOpen0(!menuOpen0);
      setMenuActive0(!menuOpen0);	// Code pour fermer le menu
    };

    const [menuOpen01, setMenuOpen01] = useState(false);
    const [menuActive01, setMenuActive01] = useState(false);
    const toggleMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);
    };
    
    const closeMenu01 = () => {
      setMenuOpen01(!menuOpen01);
      setMenuActive01(!menuOpen01);	// Code pour fermer le menu
    };


    
    const [menuOpen011, setMenuOpen011] = useState(false);
    const [menuActive011, setMenuActive011] = useState(false);
    const toggleMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);
    };
    
    const closeMenu011 = () => {
      setMenuOpen011(!menuOpen011);
      setMenuActive011(!menuOpen011);	// Code pour fermer le menu
    };

    const [inputs, setInputs] = useState([]);
    const { id } = useParams();
    const [progress, setProgress] = useState(0);

    useEffect(() => {












            
        const loadScripts = () => {
            // Remove existing script elements added by the component
            const scriptElements = Array.from(document.getElementsByTagName('script'));
            const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
      
            componentScriptElements.forEach((script) => {
              document.body.removeChild(script);
            });
      
      
        
     
          const script1 = document.createElement('script');
          script1.src = '/importe/dependencies/jquery/js/jquery.min.js';
          script1.async = true;
      
          const script2 = document.createElement('script');
          script2.src = '/importe/dependencies/popper.js/js/popper.min.js';
          script2.async = true;
  
          const script3 = document.createElement('script');
          script3.src = '/importe/dependencies/bootstrap/js/bootstrap.min.js';
          script3.async = true;
      
         
          const script5 = document.createElement('script');
          script5.src = '/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
          script5.async = true;
  
  
          const script6 = document.createElement('script');
          script6.src = '/importe/dependencies/isotope-layout/js/isotope.pkgd.min.js';
          script6.async = true;
  
          const script8 = document.createElement('script');
          script8.src = '/importe/dependencies/imagesloaded/js/imagesloaded.pkgd.min.js';
          script8.async = true;
  
          const script7 = document.createElement('script');
          script7.src = '/importe/dependencies/slick-carousel/js/slick.min.js';
          script7.async = true;
          const script4 = document.createElement('script');
          script4.src = '/importe/dependencies/magnific-popup/js/jquery.magnific-popup.min.js';
          script4.async = true;
  
          const script9 = document.createElement('script');
          script9.src = '/importe/dependencies/sal.js/sal.js';
          script9.async = true;
  
          const script10 = document.createElement('script');
          script10.src = '/importe/dependencies/sal.js/sal.js';
          script10.async = true;
  
          const script11 = document.createElement('script');
          script11.src = '/importe/dependencies/mcustomscrollbar/jquery.mCustomScrollbar.concat.min.js';
          script11.async = true;
  
          const script12 = document.createElement('script');
          script12.src = '/importe/assets/js/app.js';
          script12.async = true;
          const script13 = document.createElement('script');
          script13.src = '/importe/dependencies/select2/js/select2.min.js';
          script13.async = true;
  
  
          const script14 = document.createElement('script');
          script14.src = '/importe/dependencies/elevate-zoom/jquery.elevatezoom.js';
          script14.async = true;
          const script15 = document.createElement('script');
          script15.src = '/importe/dependencies/bootstrap-validator/js/validator.min.js';
          script15.async = true;
  
          document.body.appendChild(script1);
          document.body.appendChild(script2);
          document.body.appendChild(script3);
          document.body.appendChild(script8);
          document.body.appendChild(script5);
         
          document.body.appendChild(script6);
          document.body.appendChild(script7);
          document.body.appendChild(script9);
  
  
          document.body.appendChild(script4);
          document.body.appendChild(script10);
          document.body.appendChild(script11);
  
  
          document.body.appendChild(script13);
  
          document.body.appendChild(script14);
  
  
          document.body.appendChild(script15);
  
          document.body.appendChild(script12);
         
         
        };
          loadScripts();
      
      
          return () => {
            const scriptElements = Array.from(document.getElementsByTagName('script'));
            const componentScriptElements = scriptElements.filter((script) => script.getAttribute('data-component-script'));
      
            componentScriptElements.forEach((script) => {
              document.body.removeChild(script);
            });
          };
    

    }, []);


useEffect(() => {
    getAgences();
      
    
 
        const htmlElement = document.getElementById('wrapper');
        const htmlElement0 = document.getElementById('menu0');
        const htmlElement01 = document.getElementById('chat-box-modal');
        const htmlElement011 = document.getElementById('searchBtn');
        const htmlElement0111 = document.getElementById('users0');
        const htmlElement01111 = document.getElementById('users1');


        
        if (htmlElement) {
            if (menuActive) {
              htmlElement.classList.add('open');

              document.getElementById('menu1').classList.add('lg-menu-open');
            
            } else {
              htmlElement.classList.remove('open');
              document.getElementById('menu1').classList.remove('lg-menu-open');
             
            }
          }

          if (htmlElement0) {
            if (menuActive0) {
              htmlElement0.classList.add('chat-head-hide');

            
            
            } else {
              htmlElement0.classList.remove('chat-head-hide');
           
             
            }
          }

          if (htmlElement01) {
            if (menuActive01) {
              htmlElement01.classList.add('modal-show');

            
            
            } else {
              htmlElement01.classList.remove('modal-show');
           
             
            }

           
          }



          if (htmlElement011) {
          if (menuActive011) {
            htmlElement011.classList.add('show');

          
          
          } else {
            htmlElement011.classList.remove('show');
         
           
          }
        }

        
      
        
    
    }, [menuActive,menuActive0,menuActive01,menuActive011]);
    
        function getAgences(){
          
         axios.get(`/dist81/${id}`).then(function(response){
            console.log(response.data);
            setInputs(response.data);
           
         });
        }
      
            const handleDownload = () => {
              const fileUrl = `/${inputs.mp4}`;
              const fileName = `${inputs.mp4}`;
              downloadFile(fileUrl, fileName, setProgress);
            };
        

            const handleShareOnWhatsApp = () => {
                const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Découvrez ce clips-vidéo sur THEZIK');
    const whatsappUrl = `https://api.whatsapp.com/send?text=https://${url}`;
    window.open(whatsappUrl, '_blank');
              };
          
              const [isListening, setIsListening] = useState(false);
  const [spokenText, setSpokenText] = useState('');

  useEffect(() => {
    if (isListening) {
      startListening();
    } else {
      stopListening();
    }
  }, [isListening]);

  const startListening = () => {
    if (annyang) {
      annyang.start();
      annyang.addCallback('result', (userSaid) => {
        setSpokenText(userSaid[0]); // Update spokenText with captured text
      });
    }
  };

  const stopListening = () => {
    if (annyang) {
      annyang.abort();
    }
  };

 


  let navigate =useNavigate();
   

  const logOut = () => {
    localStorage.removeItem('id');
   
    localStorage.clear();
    navigate('/');
  };

  const { transcript, resetTranscript, listening } = useSpeechRecognition({ continuous: true });
  
  useEffect(() => {
    if (transcript) {
      setCapturedText(transcript); // Mettre à jour l'état local avec le texte transcrit
    }
  }, [transcript]);

  useEffect(() => {
    if (capturedText) {
      // Envoyer le texte transcrit au serveur PHP en utilisant une requête POST
   
      const sendData = {
        query:capturedText
    
    }
    axios.post('/search/',sendData).then((result)=>{
      
    
      setSearchResults(result.data); // Mettre à jour l'état local avec les résultats de recherche

  });
      resetTranscript(); // Réinitialise le texte transcrit après l'envoi
    }
  }, [capturedText]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition) {
    return <div>La reconnaissance vocale n'est pas prise en charge par votre navigateur.</div>;
  }

    return(
    <Fragment>

<Helmet>
        <title>THEZIK clips-videos disponibles</title>
        <meta name="description" content="THEZIK: tout pour la musique." />
        <meta property="og:title" content={`${inputs.artist} - ${inputs.titre}`} />
        
   
<meta property="og:description" content="Ecoute et télécharges ce clips-vidéo sur THEZIK gratuitement." />

        
        <meta property="og:image" content={`/${inputs.image}`} />
        <meta property="og:url" content={`/`} />
      </Helmet>
   
      <audio style={{display:'none'}} ref={audioRef} src="/assets/media/success.mp3" />

    <div id="wrapper" className="wrapper">
      
      <header className="fixed-header">
              <div className="header-menu">
              <div className="navbar">
                      <div className="nav-item d-none d-sm-block">
                          <div className="header-logo">
                              <Link to='/'><img src="/importe/media/logothezik.png" style={{height:'60px'}} alt="Logo" /></Link>
                          </div>
                      </div>
                      <div className="nav-item nav-top-menu">
                          <nav id="dropdown" className="template-main-menu">
                              <ul className="menu-content">
                                  <li className="header-nav-item">
                                      <Link to='/' className="menu-link active">Accueil</Link>
                                  </li>
                                
                                  <li className="header-nav-item">
                                              <Link to='/Videos'>Clips vidéos</Link>
                                   </li>
  
                                   <li className="header-nav-item">
                                              <Link to='/Musics'>Clips mp3</Link>
                                  </li>
                              </ul>
                          </nav>
                      </div>
                      <div className="nav-item header-control">
  
               
                          <div className="inline-item">
                              <div className="dropdown dropdown-admin">
                                  <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                      <span className="media">
                                      {!localStorage.getItem('id') ? (    
                          <>
                                          <span style={{borderRadius:'100%'}} className="item-img bg-white">
                                         
  
  
  
                                              <img src={`/importe/media/logothezik.png`} style={{height:'44PX',width:'44px'}} alt="Chat" />
                                              <span className="acc-verified"><i className="icofont-check"></i></span>
                                          </span>    
                                          <span className="media-body">
                                              <span className="item-title">THEZIK</span>
                                          </span>
                                              </>              
                                  ):(
                                    <>
                                     <span style={{borderRadius:'100%'}} className="item-img bg-white">
                                         
                                     <img src={`/importe/media/${localStorage.getItem('profil')}`} style={{height:'44PX',width:'44px'}} alt="Chat" />
                                             
                                             <span className="acc-verified"><i className="icofont-check"></i></span>
                                          </span> 
                                          <span className="media-body">
                                              <span className="item-title">{localStorage.getItem('prenom')}</span>
                                          </span>   
                                              </>
                                  )  }         
                                        
                                             
                                          
                                      </span>
                                  </button>
                                  {localStorage.getItem('id') ? (    
                          <>
                                  <div className="dropdown-menu dropdown-menu-right">
                                      <ul className="admin-options">
                                      <li><Link to='/Compte'>Mon compte</Link></li>
                                    
                                        
                                      </ul>
                                  </div>
  </>):(
      <></>
  )}
  
                              </div>
                          </div>
                       
                     
  
  
                      </div>
                  </div>
                  
              </div>
          </header>
       
          <div className="fixed-sidebar" id="menu1">
              <div className="fixed-sidebar-left small-sidebar">
                  <div className="sidebar-toggle">
                      <button onClick={toggleMenu} className="toggle-btn toggler-open">
                          <span></span>
                          <span></span>
                          <span></span>
                      </button>
                  </div>
                  <div className="sidebar-menu-wrap">
                      <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                          <ul className="side-menu">
                              <li><Link to='/' className="menu-link" data-toggle="tooltip" data-placement="right" title=" ACCUEIL"><i className="icofont-home"></i></Link></li>
                              <li><Link to='/Videos' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS-VIDEOS"><i className="icofont-play-alt-1"></i></Link></li>
                              <li><Link to='/Musics' className="menu-link" data-toggle="tooltip" data-placement="right" title="CLIPS MP3"><i className="icofont-music"></i></Link></li>
                              {localStorage.getItem('id') ? (    
                         <li><Link to='/Compte' className="menu-link" data-toggle="tooltip" data-placement="right" title="MON COMPTE"><i className="icofont-user"></i></Link></li>
                         ):(
                            <></>
                          )}
                              <li><Link to='/Apropos' className="menu-link" data-toggle="tooltip" data-placement="right" title="A PROPOS"><i className="icofont-question"></i></Link></li>
                              {localStorage.getItem('id') ? (    
                        
                        <li><Link to='/' onClick={logOut} className="menu-link" data-toggle="tooltip" data-placement="right" title="DECONNEXION"><i className="icofont-power"></i></Link></li>
                          ):(
                            <li><Link to='/Login' className="menu-link" data-toggle="tooltip" data-placement="right" title="CONNEXION"><i className="icofont-power"></i></Link></li>
                        
                        )}
                             
                          </ul>
                      </div>
                  </div>
              </div>
              <div className="fixed-sidebar-left large-sidebar bg-info">
                  <div className="sidebar-toggle">
                      <div className="sidebar-logo bg-info">
                          <a href="#"><img src="/importe/media/logothezik.png" style={{height:'50px'}} alt="Logo" /></a>
                      </div>
                      <button onClick={closeMenu} className="toggle-btn toggler-close">
                          <span></span>
                          <span></span>
                          <span></span>
                      </button>
                  </div>
                  <div className="sidebar-menu-wrap">
                      <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                          <ul className="side-menu">
                              <li><Link to='/' className="menu-link"><i className="icofont-home"></i><span className="menu-title">Accueil</span></Link></li>
                              <li><Link to='/Videos' className="menu-link active"><i className="icofont-play-alt-1"></i><span className="menu-title active">Clips-vidéos</span></Link></li>
                              <li><Link to='/Musics' className="menu-link"><i className="icofont-music"></i><span className="menu-title">Clips mp3</span></Link></li>
                              {localStorage.getItem('id') ? (    
                          
                          <li><Link to='/Compte' className="menu-link"><i className="icofont-user"></i><span className="menu-title">Mon compte</span></Link></li>
                          ):(
                            <></>
                          )}
                               <li><Link to='/Apropos' className="menu-link"><i className="icofont-question"></i><span className="menu-title">A propos</span></Link></li>
                               {localStorage.getItem('id') ? (    
                        
                        <li><Link to='/' onClick={logOut} className="menu-link"><i className="icofont-power"></i><span className="menu-title">Deconnexion</span></Link></li>
                        ):(
                          <li><Link to='/Login' className="menu-link"><i className="icofont-power"></i><span className="menu-title">Connexion</span></Link></li>
                      
                        )}
                             </ul>
                          
                      </div>
                  </div>
              </div>
          </div>
        
          <div className="fixed-sidebar right" id='menu0'>
              <div className="fixed-sidebar-right small-sidebar">
                  <div className="sidebar-toggle" id="chat-head-toggle">
                      <button className="chat-icon">
                      {!listening ? (
                        <i onClick={SpeechRecognition.startListening} className='icofont-audio'></i>
              ) : (
                <img style={{height:'30PX',borderRadius:'100%'}} src='/assets/media/au.gif'/>
                   
      )} </button>
                  </div>
                  {/* <div className="sidebar-menu-wrap">
                      <div className="mCustomScrollbar" data-mcs-theme="dark" data-mcs-axis="y">
                      {searchResults.length > 0 && (
                          <ul className="user-chat-list">
                         {searchResults.map(resultt => (
                          <>
                           {resultt.mp3!='' && (
                          <li key={resultt.id} className="chat-item chat-open">
                            

                              <div className="user-video">
                            <div align='center'>  <img style={{height:'44.5PX',width:'44.5PX'}} src={`/${resultt.image}`} alt="chat" />
                                   </div>
                      
                        <div className="video-btn">
                       
                        <Link to={`/Musics/${resultt.id}`} className="play-icon" style={{background:'transparent'}}>
                                <i style={{}} className="icofont-ui-music"></i>
                            </Link>
                       
                        </div>
                    </div>


                          </li>
                          
                          )}

                          {resultt.mp4!='' && (
                         <li key={resultt.id} className="chat-item chat-open">
                           

                             <div className="user-video">
                           <div align='center'>  <img style={{height:'44.5PX',width:'44.5PX'}} src={`/${resultt.image}`} alt="chat" />
                                  </div>
                     
                       <div className="video-btn">
                      
                       <Link to={`/Musics/${resultt.id}`} className="play-icon" style={{background:'transparent'}}>
                               <i style={{}} className="icofont-ui-music"></i>
                           </Link>
                      
                       </div>
                   </div>


                         </li>
                         
                         )}</>

                         ))}

                      </ul>
    
      
      )}
              
                      </div>
                  </div> */}
              </div>
          </div>
 
        <div className="page-content">

        <div className="container">
       
     

      

                <div style={{marginTop:'-20PX'}} className="block-box user-single-blog row">

                

                  
                  {isMobile ? (
                                  <>
                    <div className="blog-thumbnail col-lg-8 col-xl-8 col-sm-12 col-md-8">
                    <video controls  src={`/${inputs.mp4}`} style={{width:'100%',maxHeight:'500px'}}>
            
            </video>
            </div>
            </>):(

<>
<div className="blog-thumbnail col-lg-8 col-xl-8 col-sm-12 col-md-8">
                    <video controls  src={`/${inputs.mp4}`} style={{width:'100%',maxHeight:'500px'}}>
            
            </video>
            </div>
</>
                  )}
                   
                

                    <div className="blog-content-wrap col-lg-4 col-xl-4 col-sm-12 col-md-4">
                        <div className="blog-entry-header">
                           





                            <h3 className="entry-title">{inputs.artist}</h3>
                         
                            <h3 className='text-info' style={{marginTop:'0PX'}}>{inputs.titre}</h3>
                           
                            <div className="row align-items-center">

                            {progress && progress!==100 ? (
    <> Téléchargement en cours...{progress}%
       
                            <div style={progressBarStyles} className="progress-bar">
                                
                                
        <div className="progress" style={{ width: `${progress}%`, height: '100%',
          backgroundColor: '#4CAF50',
        
          transition: 'width 0.3s ease-in-out', }}></div>
      </div>
      </>

) : ( 
    <span></span> 
)}

                                <div className='col-12' id='pour'></div>
                                {isMobile ? (
                                  <>
                                <div className="col-lg-12">
                                <hr />   
                                        <ul style={{marginTop:'15PX'}} className="entry-meta">
                                        <li style={{fontSize:'16PX',color:'blue'}}><i className="icofont-calendar"></i> {inputs.datepub}</li>
                                        <li style={{fontSize:'16PX',color:'teal'}}><i className="icofont-music"></i> taille: {inputs.taille2}</li>
                                        
                                    </ul>
                                </div>  
                                <div className="col-lg-12">
                                    <ul className="blog-share">
                                    <li><a  onClick={handleDownload} className="bg-behance"><i className="icofont-download"></i></a></li>
                                   
                                         <li><a  onClick={handleShareOnWhatsApp} className="bg-info"><i className="icofont-whatsapp"></i></a></li>
                                          </ul>
                                </div>
                                </>):(
                                    <>
                                    
                                    <div >
                                    <div className="col-lg-12">   <hr />
                                        <ul className="entry-meta" style={{marginTop:'15PX'}}>
                                            
                                            <li style={{fontSize:'23PX',color:'blue',marginTop:'10PX'}}><i className="icofont-calendar"></i> {inputs.datepub}</li>
                                            <li style={{fontSize:'23PX',color:'teal',marginTop:'10PX'}}><i className="icofont-music"></i> taille: {inputs.taille2}</li>
                                            
                                        </ul>
                                    </div>
                                    
                                    </div>

<div className="col-lg-12" style={{position:'absolute',bottom:'60px',right:'235PX'}}>
<ul className="blog-share">
<li><a  onClick={handleDownload} className="bg-behance"><i style={{fontSize:'18PX'}} className="icofont-download"></i></a></li>

     <li><a  onClick={handleShareOnWhatsApp} className="bg-info"><i style={{fontSize:'18PX'}} className="icofont-whatsapp"></i></a></li>
      </ul>
</div>
                                    </>
                                )}
    



                            </div>
                        </div>
                     
                     
                    
                    </div>
                </div>
               
                {searchResults.length > 0 && (
          <>
          <div className="block-box load-more-btn col-lg-12">
            
          <a href={`https://thezik.org/${id}`} className="item-btn"><i className="icofont-trash"></i>Effacer le résultat</a>
      </div>
      
      {!isMobile ? 
                    <>
      <div id="user-view" className="user-grid-view">

        <div className="row gutters-20">





      
          
 {searchResults.map(resultt => (
                          <>
                           {resultt.mp4!='' && (
                            <>
    
<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img" style={{height:'200PX'}}>
                                        </div>
                                    <div className="profile-img user-video bg-warning">
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-play"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h4 className=" "> <Link style={{color:'black'}} className="" to={`/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-primary" style={{fontSize:'17PX'}}>{resultt.taille2}</span><br />

                                 </Link>
                                </h4>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                           )}

{resultt.mp3!='' && (
                            <>
     

<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                       </div>
                                    <div className="profile-img user-video bg-warning" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/Musics/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-music"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h4 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/Musics/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-primary" style={{fontSize:'17PX'}}>{resultt.taille}</span><br />

                                 </Link>
                                </h4>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                           )}
                            </>
                           ))}
          </div></div>
          
          </>:
           <>
           <div id="user-view" className="user-list-view">

<div className="row gutters-20">






  
{searchResults.map(resultt => (
                  <>
                   {resultt.mp4!='' && (
                    <>


<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                        <img style={{height:'96PX',width:'277PX'}} src={`/${resultt.image}`} alt="cover" />
                                    </div>
                                    <div className="profile-img user-video" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-play"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h5 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/${resultt.id}`}>{resultt.artist.length > 13
                    ? `${resultt.artist.slice(0, 13)}...`
                    : resultt.artist}
                               <br /><span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-warning" style={{fontSize:'15PX'}}>{resultt.taille2}</span><br />

                                 </Link>
                                </h5>
                                   </div>
                                </div>
                              
                            </div>
                        </div>

</>
                   )}

{resultt.mp3!='' && (
                    <>


<div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="widget-author user-group" style={{border:'1px solid white'}}>
                                <div className="author-heading">
                                    <div className="cover-img">
                                             </div>
                                    <div className="profile-img user-video" style={{minWidth:'100PX'}}>
                                        <a href="#">
                                            <img style={{height:'90PX',width:'90PX',borderRadius:'100%'}} src={`/${resultt.image}`} alt="author" />
                                        </a>
                                        <div className="video-btn">


                                

                                     <Link to={`/Musics/${resultt.id}`} className="play-icon">
                                      <i className="icofont-ui-music"></i>
                                    </Link>
                                       </div>
                                    </div>
                                    <div className="profile-name">
                                    <h5 className="blog-title text-black"> <Link style={{color:'black'}} className="" to={`/Musics/${resultt.id}`}>{resultt.artist.length > 12
                    ? `${resultt.artist.slice(0, 12)}...`
                    : resultt.artist}
                               <br />
                               <span className="text-info">{resultt.titre.length > 13
                    ? `${resultt.titre.slice(0, 13)}...`
                    : resultt.titre}</span>
                                <br /> <span className="text-warning" style={{fontSize:'15PX'}}>{resultt.taille}</span><br />

                                 </Link>
                                </h5>
                                   </div>
                                </div>
                              
                            </div>
                        </div>


</>
                   )}
                    </>
                   ))}
  </div></div>
           </>}   </>
        )}  
            </div>
           
        



        </div>

     



    </div>
</Fragment>
    )

}

export default Details;