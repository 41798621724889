import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Login from './Pages/Login';
import Details from './Pages/Details';
import Genres from './Pages/Genres';
import Genre from './Pages/Genre';
import Musics from './Pages/Musics';
import Detail from './Pages/Detail';
import Accueil from './Pages/Accueil';
import Connexion from './Pages/Connexion';
import Compte from './Pages/Compte';
import Comment from './Pages/Comment';
import Ok from './Pages/Ok';
import Apropos from './Pages/Apropos';
import Login1 from './Pages/Login1';



function App() {
  return (
    <div className="App">
             <Router>
      <Routes>
        
        <Route path='/Videos' element={<Login />} />
        <Route path='/Compte' element={<Compte />} />
        
        <Route path='/:id' element={<Details />} />
        <Route path='/Genres/:id' element={<Genres />} />
        <Route path='/Genre/:id' element={<Genre />} />
        <Route path='/Musics' element={<Musics />} />
        <Route path='/Musics/:id' element={<Detail />} />
        <Route path='/Accueil' element={<Accueil />} />
        <Route path='/Login' element={<Connexion />} />
        <Route path='/Comment/:idpu' element={<Comment />} />
        <Route path='/Apropos' element={<Apropos />} />
        <Route path='/vid/:id' element={<Login1 />} />
        <Route path='/' element={<Accueil />} />
        <Route path='*' element={<Accueil />} />
        
        <Route path='/Ok' element={<Ok />} />
      </Routes> 
     </Router>

    </div>
  );
}

export default App;
